import axios from "axios"

const actions = {
  getReportingPeriod(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/reporting_periods.json").then(response => {
        let reportingPeriods = response.data;
        context.commit("SET_REPORTING_PERIODS", reportingPeriods);
        resolve();
      }).catch(errors => {
        reject(errors);
      })
    })
  }
};

const mutations = {
  SET_REPORTING_PERIODS(context, payload) {
    context.reportingPeriods = payload;
  }
};

const state = {
  reportingPeriods: {}
};

const getters = {
  getReportingPeriods(state) {
    return state.reportingPeriods
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}
