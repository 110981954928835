<template lang="pug">
  .accordion-wrapper
    .filter-block
      .filter-block-title
        | рівень прозорості
      .filter-block-mark
        div( v-for="n in 5" :class="[markClasses[n - 1]]") {{ n }}
      .filter-block-btn
        i.fas.fa-cog.fa-xs#filter-block-btn-icon( title="Фільтри" @click="showFiltersModal")
    #deputies-accordion.accordion
      .card( v-for="(deputy, index) in deputies")
        .card-header( :id="'heading' + index" ref="card-header" @click="changeBackgroundColor(index)")
          img.deputy-image( :src="deputy[0].value" @click="$router.push('/councils/deputy/' + deputy[1].id)")
          button.btn.btn-link(type="button" data-toggle="collapse" :data-target="'#collapse' + index" aria-expanded="false" :aria-controls="'collapse' + index")
            span {{deputy[1].value}}
            span {{deputy[2].value}}
          .mark-value( :class="[markClasses[deputy[3].value - 1]]")
            | {{deputy[3].value}}
        .collapse(:id="'collapse' + index" :aria-labelledby="'heading' + index" data-parent="#deputies-accordion")
          .card-body
            .card-body-mark( v-for="(values, index) in deputy.slice(4)" v-if="markValueName(values.column_title_alias).show")
              .card-body-mark-name {{markValueName(values.column_title_alias).name}}
              .card-body-mark-value( v-html="valueFormatter(values.value)")
    .more-deputies
      button.btn.show-more-deputies-button( @click="showMoreDeputies()" :class="{ disabled: disableMoreDeputiesButton }")
        | Більше результатів
</template>

<script>
export default {
  name: "MobileTable",
  props: ["columnTitles", "deputies", "disableMoreDeputiesButton", "markClass"],
  data() {
    return {
      showIndex: null,
      markClasses: [
        "mark-red",
        "mark-orange",
        "mark-pink",
        "mark-blue",
        "mark-dark-blue",
      ],
      na: {
        value: -1,
        title: "&#8211;",
      },
    };
  },
  methods: {
    changeSort(sortMethod) {
      this.$emit("change-sort", sortMethod);
    },
    showFiltersModal() {
      this.$emit("show-filters-modal");
    },
    isDeputyFullName(cellValue) {
      return cellValue.column_title_alias === "full_name";
    },
    totalClass(cellValue) {
      return cellValue.column_title_alias === "TOTAL"
        ? this.markClass[cellValue.value - 1]
        : "";
    },
    valueFormatter(value) {
      return value === this.na.value ? this.na.title : value;
    },
    showMoreDeputies() {
      this.$emit("show-more-deputies");
    },
    changeBackgroundColor(index) {
      if (this.showIndex !== null)
        this.$refs["card-header"][this.showIndex].style.backgroundColor = "";
      this.$refs["card-header"][index].style.backgroundColor =
        this.showIndex !== index ? "#ddece9" : "";
      this.showIndex = index;
    },
    markValueName(alias) {
      for (let columnTitle of this.columnTitles) {
        if (columnTitle.alias === alias) return columnTitle;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-wrapper {
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: #ffffff;
  .mark-red {
    background-color: #e31e24;
  }

  .mark-orange {
    background-color: #ec474b;
  }

  .mark-pink {
    background-color: #ff9999;
  }

  .mark-blue {
    background-color: #67c3e8;
  }

  .mark-dark-blue {
    background-color: #00a0e3;
  }
  .filter-block {
    display: inline-flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
    .filter-block-title {
      color: #000000;
      font-family: "Open Sans";
      font-size: 15px;
      font-weight: 400;
      text-transform: uppercase;
    }
    .filter-block-mark {
      display: inline-flex;
      div {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        font-family: "Open Sans";
        font-size: 10px;
        font-weight: 400;
        margin-right: 5px;
      }
    }
    .filter-block-btn {
      margin-left: 10px;
      #filter-block-btn-icon {
        padding: 9px;
      }
    }
  }
  .accordion {
    .card {
      border: none;
      &:nth-of-type(even) .card-header {
        background-color: white;
      }
      .card-header {
        display: inline-flex;
        height: 42px;
        padding: 5px;
        border-color: white;

        .deputy-image {
          width: 32px;
          min-width: 32px;
          height: 32px;
        }
        .btn-link {
          text-decoration: none;
          line-height: 16px;
          margin-left: 5px;
          padding: 0;
          height: 32px;
          display: inline-flex;
          color: #222222;
          font-family: "Open Sans";
          font-size: 12px;
          font-weight: 400;
          text-align: left;
          width: 100%;
          align-items: center;
          span:nth-child(1) {
            min-width: 40%;
            max-width: 40%;
          }
        }
        .mark-value {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 32px;
          height: 32px;
          margin-left: auto;
          color: white;
          font-size: 10px;
          font-weight: 400;
        }
      }
      .card-body {
        background-color: #ddece9;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-top: 1px solid white;
        .card-body-mark {
          display: inline-flex;
          justify-content: space-between;
          margin-bottom: 5px;
          align-items: center;
          .card-body-mark-name {
            font-size: 12px;
            max-width: 90%;
          }
          .card-body-mark-value {
            color: #222222;
            font-family: "Open Sans";
            font-size: 15px;
            font-weight: 700;
            min-width: 9px;
          }
        }
      }
    }
  }
}
</style>
