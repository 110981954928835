<template lang="pug">
  .table-of-deputies-container
    .top-filter-block
      .level-of-perspicuity.d-block.d-sm-flex
        .title Рівень прозорості
      .d-block.d-sm-flex.align-items-sm-center.info-block
        .group-marks
          .marks.d-flex.d-sm-block
            template( v-for="n in 5")
              span {{ n }}
      .level-of-perspicuity.d-block.d-sm-flex
        .convocation {{convocationNumber}} скликання
      .d-block.d-sm-flex.align-items-sm-center.info-block.ml-auto
        i.fas.fa-cog.fa-xs( title="Фільтри" @click="showFiltersModal")
    .table-responsive.table-of-deputies
      table.table.table-hover
        thead( v-if="columnTitles")
          tr
            th(scope='col')
            th(scope='col')
            th(scope='col')
            template( v-for="columnTitle in columnTitles")
              ColumnHead( :object="columnTitle" :ref="columnTitle.alias" v-on:change-sort="changeSort" v-if="columnTitle.show")
        tbody( v-if="deputies")
          template( v-for="(deputy, index) in deputies")
            tr( :class="{ checked: deputy.selected }")
              td(scope='row')
                input( type="checkbox" v-model="deputy.selected")
              td {{ index + 1 }}
              td
                img.deputy-image( :src="deputy[0].value")
              template( v-for="(values, index) in deputy.slice(1)" v-if="columnTitles[index].show")
                td
                  router-link( v-if="isDeputyFullName(values)" :to="'/councils/deputy/' + values.id" class="show-deputy")
                    | {{ values.value }}
                  a( v-else-if="isCouncil(values)" class="show-deputy" @click.prevent="goToCounsil(values.council_id)")
                    | {{ values.value }}
                  span( v-else :class="totalClass(values)")
                    | {{ valueFormatter(values.value) }}
    .more-deputies
      button.btn.show-more-deputies-button( @click="showMoreDeputies()" :class="{ disabled: disableMoreDeputiesButton }")
        | Більше результатів
</template>

<script>
import ColumnHead from '../../home/ColumnHeader'

export default {
  name: 'DesktopTable',
  props: [
    'columnTitles',
    'deputies',
    'disableMoreDeputiesButton',
    'na',
    'markClass',
    'convocationNumber'
  ],
  components: {
    ColumnHead
  },
  methods: {
    changeSort(sortMethod) {
      this.$emit('change-sort', sortMethod)
    },
    showFiltersModal() {
      this.$emit('show-filters-modal')
    },
    isDeputyFullName(cellValue) {
      return cellValue.column_title_alias === 'full_name'
    },
    isCouncil(column) {
      return column.column_title_alias === 'council_title'
    },
    totalClass(cellValue) {
      return cellValue.column_title_alias === 'TOTAL'
        ? this.markClass[cellValue.value - 1]
        : ''
    },
    valueFormatter(value) {
      return value === this.na.value ? this.na.title : value
    },
    showMoreDeputies() {
      this.$emit('show-more-deputies')
    },
    goToCounsil(councilID) {
      this.$router.push({ path:`/councils?council_id=${councilID}`})
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  div.table-of-deputies-container {
    margin-top: 10px;
    margin-right: 0;

    div.table-of-deputies {
      thead,
      tbody {
        tr {
          th,
          td {
            &:nth-child(-n + 6) {
              position: inherit;
              background-color: transparent;
            }

            &:nth-child(4) {
              position: -webkit-sticky;
              position: sticky;
              background-color: inherit;
              left: -1px;
            }
          }
        }
      }
    }
  }
}

.table-of-deputies-container {
  margin-top: -80px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: #ffffff;

  .top-filter-block {
    display: flex;
    align-items: center;
    color: white;
    padding: 11px 15px;
    margin: 0 5px;
    border-bottom: 1px solid #eeeeee;

    .level-of-perspicuity {
      align-items: center;
      .convocation {
        border-left: 2px solid #f4f4f4;
        padding-left: 50px;
        color: black;
        margin-left: 50px;
      }
      .title {
        margin-right: 15px;
        padding: 0;
        color: black;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }

  .info-block {
    color: black;
    font-size: 12px;
    max-width: 380px;

    span:nth-child(1) {
      margin-left: 10px;
      margin-right: 5px;
      padding: 0;
      width: 10px;
    }

    span:nth-child(2) {
      padding: 0;
      width: 10px;
    }

    span:nth-child(3) {
      color: #a2a2a2;
    }

    .marks {
      flex-wrap: wrap;
      margin-bottom: 3px;

      span {
        margin-left: 0;
        border-radius: 2px;
        font-size: 12px;
        padding: 0 12px;
        margin-right: 2px;
        font-weight: 400;
        width: auto;
        color: white;
      }

      span:nth-child(1) {
        background-color: #e31e24;
      }

      span:nth-child(2) {
        background-color: #ec474b;
      }

      span:nth-child(3) {
        background-color: #ff9999;
      }

      span:nth-child(4) {
        background-color: #67c3e8;
      }

      span:nth-child(5) {
        background-color: #00a0e3;
        margin-right: auto;
      }
    }
  }

  .table-of-deputies {
    font-size: 12px;

    &::-webkit-scrollbar-track {
      background-color: #b8b8b8;
    }

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 271px;
      background-color: #626262;
    }

    thead,
    tbody {
      tr {
        th,
        td {
          border: none;
          text-align: center;
          vertical-align: middle;
          position: -webkit-sticky;
          position: sticky;
          background-color: inherit;

          &:nth-child(-n + 6) {
            z-index: 1;
          }

          &:nth-child(1) {
            left: 0;
            width: 37px;
          }

          &:nth-child(2) {
            padding: 0;
            left: 37px;
            width: 15px;
            min-width: 15px;
            max-width: 15px;
          }

          &:nth-child(3) {
            left: 52px;
            width: 65px;
            min-width: 65px;
            max-width: 65px;
          }

          &:nth-child(4) {
            left: 117px;
            width: 115px;
            min-width: 115px;
            max-width: 115px;
            word-break: break-word;
          }

          &:nth-child(5) {
            left: 232px;
            width: 115px;
            min-width: 115px;
            max-width: 115px;
            word-break: break-word;
          }

          &:nth-child(6) {
            left: 347px;
            width: 100px;
            min-width: 100px;
            max-width: 100px;
          }

          &:nth-child(n + 7) {
            .column-header {
              .column-title {
                &:after {
                  content: '';
                  position: absolute;
                  right: 23px;
                  top: 15px;
                  width: 50px;
                  height: inherit;
                  background: rgba(237, 237, 237, 0);
                  background: -moz-linear-gradient(
                    left,
                    rgba(237, 237, 237, 0) 0%,
                    rgba(246, 246, 246, 0.06) 5%,
                    rgba(255, 255, 255, 0.18) 16%,
                    rgba(255, 255, 255, 1) 87%
                  );
                  background: -webkit-gradient(
                    left top,
                    right top,
                    color-stop(0%, rgba(237, 237, 237, 0)),
                    color-stop(5%, rgba(246, 246, 246, 0.06)),
                    color-stop(16%, rgba(255, 255, 255, 0.18)),
                    color-stop(87%, rgba(255, 255, 255, 1))
                  );
                  background: -webkit-linear-gradient(
                    left,
                    rgba(237, 237, 237, 0) 0%,
                    rgba(246, 246, 246, 0.06) 5%,
                    rgba(255, 255, 255, 0.18) 16%,
                    rgba(255, 255, 255, 1) 87%
                  );
                  background: -o-linear-gradient(
                    left,
                    rgba(237, 237, 237, 0) 0%,
                    rgba(246, 246, 246, 0.06) 5%,
                    rgba(255, 255, 255, 0.18) 16%,
                    rgba(255, 255, 255, 1) 87%
                  );
                  background: -ms-linear-gradient(
                    left,
                    rgba(237, 237, 237, 0) 0%,
                    rgba(246, 246, 246, 0.06) 5%,
                    rgba(255, 255, 255, 0.18) 16%,
                    rgba(255, 255, 255, 1) 87%
                  );
                  background: linear-gradient(
                    to right,
                    rgba(237, 237, 237, 0) 0%,
                    rgba(246, 246, 246, 0.06) 5%,
                    rgba(255, 255, 255, 0.18) 16%,
                    rgba(255, 255, 255, 1) 87%
                  );
                  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#ffffff', GradientType=1);
                }
              }
            }
          }

          .show-deputy {
            color: #212529;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            } 
          }
        }
      }
    }

    thead {
      tr {
        background-color: white;

        th {
          text-align: left;
        }
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f2f2f2;
        }

        &:nth-child(even) {
          background-color: white;
        }

        .checked {
          -webkit-box-shadow: inset 0 0 0 200px rgba(187, 241, 227, 0.6);
          -moz-box-shadow: inset 0 0 0 200px rgba(187, 241, 227, 0.6);
          box-shadow: inset 0 0 0 200px rgba(187, 241, 227, 0.6);
        }

        .deputy-image {
          width: 40px;
          height: 40px;
        }

        .mark-1,
        .mark-2,
        .mark-3,
        .mark-4,
        .mark-5 {
          padding: 7px 10px;
          color: white;
        }

        .mark-1 {
          background-color: #e31e24;
        }

        .mark-2 {
          background-color: #ec474b;
        }

        .mark-3 {
          background-color: #ff9999;
        }

        .mark-4 {
          background-color: #67c3e8;
        }

        .mark-5 {
          background-color: #00a0e3;
        }
      }
    }
  }
}
</style>
