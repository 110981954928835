const actions = {
  getBreadcrumbsMain(context, payload) {
    context.commit('SET_BREADCRUMBS_MAIN', payload);
  },
  getBreadcrumbsSub(context, payload) {
    context.commit('SET_BREADCRUMBS_SUB', payload);
  }
};

const mutations = {
  SET_BREADCRUMBS_MAIN(context, payload) {
    context.bMain = payload
  },
  SET_BREADCRUMBS_SUB(context, payload) {
    context.bSub = payload
  }
};

const state = {
  bMain: {},
  bSub: ''
};

const getters = {
  getBreadcrumbsMain(state) {
    return state.bMain
  },
  getBreadcrumbsSub(state) {
    return state.bSub
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}