<template lang="pug">
  .container-fluid#deputy-container
    .container
      Breadcrumbs(:current="deputy.full_name")
      template(v-if="isShow")
        deputy-info
        deputy-data(@file="onPreparedFile")
        hr( v-if="!isMobile()")
        hr(v-else)
        articles(:file="file")
</template>

<script>
  import deputyInfo from './deputies/DeputyInfo.vue'
  import deputyData from './deputies/DeputyData.vue'
  import articles from './deputies/Articles'
  import Breadcrumbs from '../layout/Breadcrumbs'
  import { mapActions, mapGetters } from 'vuex'
  import {isMobile} from '../utils/mobileDetect'
  export default {
    name: "DeputyShow",
    components: {
      "deputy-info": deputyInfo,
      "deputy-data": deputyData,
      "articles": articles,
      Breadcrumbs
    },
    data() {
      return {
        isShow: false,
        file: ''
      }
    },
    computed: {
      ...mapGetters({
        deputy: 'deputies/getDeputy'
      })
    },
    created() {
      this.getDeputy();
    },
    methods: {
      isMobile,
      ...mapActions({
        deputySetter: 'deputies/getDeputy',
        currentCouncilIdAction: 'councils/setCurrentCouncilId'
      }),
      async getDeputy() {
        await this.deputySetter(this.$route.params.id)
        await this.currentCouncilIdAction(this.deputy.council_id)
        this.isShow = true;
      },
      onPreparedFile(file) {
        this.file = file
      }
    }
  }
</script>

<style lang="scss">
  #deputy-container {
    padding: 30px 0;
    background-color: #F8F8F8;

    .breadcrumb-custom {
      margin: 0;
      color: gray;
      list-style: none;
      text-align: left;

      ul {
        li {
          color: gray;
          a {
            text-decoration: none;
          }
        }
      }
    }

    hr {
      padding: 15px 0;
    }
  }

  @media screen and (max-width: 767px) {
    #deputy-container {
      padding-top: 10px;
      hr {
        padding: 0 0;
        margin-bottom: 10px;
        margin-top: 0;
      }
    }
  }
</style>
