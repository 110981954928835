import Vue from 'vue'
import App from '../layout/App.vue'
import router from '../routes/application'
import axios from 'axios'
import vmodal from 'vue-js-modal'
import VShowSlide from 'v-show-slide'
// import Meta from 'vue-meta';
import store from '../store/store'
import EasySlider from 'vue-easy-slider'
import VueLuxon from "vue-luxon";
const SocialSharing = require('vue-social-sharing');

// Vue.use(Meta);
Vue.use(SocialSharing);
Vue.use(VueLuxon, {
  templates: {},
  output: {
    zone: 'locale',
    format: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    locale: 'uk'
  }
});

Vue.use(VShowSlide);
Vue.use(vmodal, {
  dialog: true,
  dynamic: true,
  injectModalsContainer: true
});
Vue.use(EasySlider);

let qs = require('qs');
let token = document.getElementsByName('csrf-token')[0].getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, {arrayFormat: 'repeat'});
};


document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    router,
    axios,
    store,
    el: '#app',
    render: h => h(App)
  })
});
