<template lang="pug">
  .map-container( v-if="!isMobile() || !isParamsIdIndex")
    template( v-if="!isError")
      .iframe(v-html="councilMap.iframe")
    template( v-else)
      .error
        | {{ isError }}
</template>

<script>
import {isMobile} from '../../utils/mobileDetect'
export default {
  name: 'ReceptionMaps',
  data() {
    return {
      councilMap: [],
      isError: false
    }
  },
  created() {
    if (this.$route.params.id) {
      this.getCouncilMap()
    }
  },
  watch: {
    $route(to, from) {
      this.getCouncilMap()
    }
  },
  computed: {
    isParamsIdIndex() {
      return this.$route.params.id === 'index'
    }
  },
  methods: {
    isMobile,
    getCouncilMap() {
      if (!this.isParamsIdIndex) {
        this.$store
          .dispatch('councils/getCouncilMap', this.$route.params.id)
          .then(() => {
            this.isError = false
            this.councilMap = this.$store.getters['councils/getCouncilMap']
          })
          .catch(() => {
            this.isError = this.$store.getters['councils/getError']
          })
      }
    }
  }
}
</script>

<style lang="scss">
.map-container {
  width: 770px;
  padding: 20px;
  margin-top: -65px;
  border-radius: 4px;
  height: calc(100% + 65px);
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  .iframe {
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-width: 767px) {
  .map-container {
    width: 100%;
    padding: 0;
    margin-top: 18px;
    border-radius: 2px;
    box-shadow: none;
    height: 630px;
  }
}
</style>
