<template lang="pug">
  .single-council
      .table-responsive.table-of-deputies
        .header-wrapper
          h3.table-title Персональні сторінки депутатів
          a(v-if="councilWebsite" :href="councilWebsite" target="_blank")
            | {{council}}
        div(v-if="isMobile()")
          .table.table-hover.table-striped
          hr
          template(v-for="(deputy,index) in deputies")
            .table-block
              .table-block-name
                img.deputy-image( :src="deputy.image_url")
                span 
                  router-link.show-deputy-links( :to="'/councils/deputy/' + deputy.deputy_id")
                    | {{deputy.full_name}}
              .table-block-fraction
                | Міська рада : {{ deputy.council_title }}
              .table-block-fraction
                | Франкція : {{ deputy.fraction_title }}
              .table-block-party
                | Партія, від якої обрано : {{ deputy.part_title }}
        div(v-if="!isMobile()")
          table.table.table-hover.table-striped
            thead
              tr
                th( scope='col')
                th( scope='col')
                template( v-for="title in columnTitles")
                  column-head( :object="title" :ref="title.alias" v-on:change-sort="sendQueryWithDelay")
            tbody
              template( v-for="(deputy, index) in deputies")
                tr
                  th( scope='row') {{ index + 1 }}
                  td
                    img.deputy-image( :src="deputy.image_url")
                  td
                    span
                      router-link.show-deputy-link( :to="'/councils/deputy/' + deputy.deputy_id")
                        | {{ deputy.full_name }}
                  td
                    span
                      | {{ deputy.council_title }}
                  td
                    span
                      | {{ deputy.fraction_title }}
                  td
                    span
                      | {{ deputy.part_title }}

      .more-results
        button.btn.show-more-results-button( @click="showMoreDeputies()" :class="{ disabled: disableMoreDeputiesButton }")
          | Більше результатів
</template>

<script>
import columnHead from '../home/ColumnHeader'
import { mapGetters } from 'vuex'
import { isMobile } from '../../utils/mobileDetect'
export default {
  name: 'Council',
  components: {
    columnHead
  },
  props: {
    council: {
      type: String,
      default: '' 
    },
    allDeputies: {
      type: Array,
      default: () => []
    },
    councilWebsite: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timerDelay: 1000,
      timer: null,
      sortedDeputies: [],
      open: false,
      query: {
        alias: '',
        sortType: ''
      },
      perPage: 10,
      columnTitles: [
        { name: "Ім'я депутата", alias: 'full_name' },
        { name: 'Міська рада', alias: 'council_title' },
        { name: 'Фракція', alias: 'fraction_title' },
        { name: 'Партія, від якої обрано', alias: 'part_title' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentCouncilId: 'councils/getCurrentCouncilId'
    }),
    deputies() {
      return (this.query.sortType.length
        ? this.sortedDeputies
        : this.allDeputies
      ).slice(0, this.perPage)
    },
    disableMoreDeputiesButton() {
      return this.perPage > this.allDeputies.length
    }
  },
  methods: {
    isMobile,
    showMoreDeputies() {
      this.perPage += 10
    },
    sendQueryWithDelay(method) {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.changeSort(method)
      }, this.timerDelay)
    },
    changeSort(method) {
      Object.assign(this.query, method)
      Object.assign(this.sortedDeputies, this.allDeputies)
      this.sortedDeputies.sort((a, b) =>
        a[this.query.alias].localeCompare(b[this.query.alias], 'ua', {
          ignorePunctuation: true
        })
      )
      if (this.query.sortType === 'asc') {
        this.sortedDeputies.reverse()
      }
    },
    toggleTable() {
      this.open = !this.open
      this.perPage = 10
    }
  }
}
</script>

<style lang="scss">
.single-council {
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-bottom: 5px;

  .caret-container {
    font-size: 9px;
  }

  .table-of-deputies {
    padding: 0 10px;
    
    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      a {
        color: #222222;
        border: none;
        margin-right: 10px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: #b8b8b8;
    }

    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      width: 271px;
      background-color: #626262;
    }
    table {
      margin-bottom: 0;
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;

      * {
        color: #222222;
        border: none;
      }

      tbody {
        tr {
          th {
            font-weight: normal;
          }
        }
      }
    }

    .deputy-image {
      width: 40px;
      height: 40px;
    }

    .table-block {
      background: #ffffff;
      border: 1px solid #eeeeee;
      box-sizing: border-box;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      margin-top: 21px;

      .table-block-name {
        height: 59px;
        background: #f8f8f8;
        display: flex;
        align-items: center;
        padding-left: 10px;

        .deputy-image {
          width: 40px;
          height: 40px;
        }

        .show-deputy-links {
          padding-left: 2px;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #222222;
        }
      }
      .table-block-fraction,
      .table-block-party {
        padding: 17px 10px;
        border-top: 1px solid #eeeeee;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;

        color: #222222;
      }
    }
  }

  .council-header {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;
    font-size: 18px;
    height: 65px;
    span {
      text-align: left;
    }

    i.fas.fa-chevron-down {
      transition: all 0.3s linear;
    }
  }

  .more-results {
    display: flex;
    justify-content: center;
    padding: 18px 0;

    .show-more-results-button {
      outline: 0 !important;
      border-color: initial;
      box-shadow: none;
      width: 180px;
      height: 31px;
      border-radius: 4px;
      background-color: #05a081;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 0.3s linear;
      color: white;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      ::-moz-focus-outer,
      ::-moz-focus-inner {
        border: 0;
        padding: 0;
      }

      &:hover {
        opacity: 1;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.column-header .column-title {
  display: flex;
  align-items: center;
}

.table-title {
  font-size: 18px;
  line-height: 24px;
  padding: 20px 0 20px 10px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .single-council {
    margin-bottom: 10px;

    .table-of-deputies {
      margin-top: -15px;
    }

    .btn.council-header {
      height: auto;
      padding: 20px 10px;
      span {
        font-size: 16px;
      }
    }
    .more-results {
      padding: 30px 10px 20px 10px;
      .show-more-results-button {
        width: 100%;
        height: 46px;
        font-size: 14px;
      }
    }
  }
}
</style>
