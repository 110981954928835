<template lang="pug">
  .container-fluid#article-container
    .container( v-if="article")
      Breadcrumbs( :current="article.title")
      .article
        .row
          .col-md-3.left-block
            .image
              img(:src="article.image")
            .creators
              .creators-council {{ article.council }}
              .creators-deputy
                template(v-for="(deputy, index) in article.deputies")
                  router-link(:to="'/councils/deputy/' + deputy.id")
                    | {{ deputy.full_name }}
                  span.coma(v-if="index !== article.deputies.length - 1")
                    | ,
            tags(:tags="article.tags")
          .col-md-9.right-block
            .title-block
              .title(v-html="article.title")
              .title-public
                .publication(v-html="article.publication_time")
                .facebook(v-if="!isMobile()")
                  social-sharing(
                    :url="url"
                    inline-template
                  )
                    network(network="facebook")
                      i.fab.fa-facebook-f
                .facebook(v-if="isMobile()")
                  img(:src="networks")
              //facebook(:url="url" scale="1")
                //i.fab.fa-facebook-f
            .description#description(v-html="article.description")
            .row
              .attach-files
                template(v-for="file in article.files")
                  .file.col-6.col-md-6
                    a.file-link(:href="file.url" target="_blank")
                      .file-image
                        img(:src="paperclip")
                      .file-title(v-html="file.name")
          .col-md-9.offset-md-3
            .attach-images
              gallery(:images="images" :index="index" @close="index=null")
              div(class="image" v-for="(image, imageIndex) in images" :key="imageIndex" @click="index=imageIndex" :style="{backgroundImage: 'url(' + image + ')'}")
      hr.container-hr
      articles-list
</template>

<script>
  import articlesList from './articles/ArticlesList'
  import Breadcrumbs from '../layout/Breadcrumbs'
  import tags from './articles/Tags'
  import VueGallery from 'vue-gallery'
  import imagesFix from '../utils/imagesFix'
  import {isMobile} from '../utils/mobileDetect'
  // import {Facebook} from 'vue-socialmedia-share';

  export default {
    name: "ArticleShow",
    components: {
      "articles-list": articlesList,
      Breadcrumbs,
      tags,
      'gallery': VueGallery,
      // Facebook
    },
    data() {
      return {
        images: [],
        index: null,
        article: null,
        url: window.location.href,
        descriptionObserver: null
      }
    },
    computed: {
      paperclip() {
        return require('@/paperclip.png')
      },
      isArticlePresent() {
        return Object.keys(this.article).length > 0
      },
      networks(){
         return require('@/networks.png')
      }
    },
    created() {
      this.getArticle();
    },
    beforeDestroy() {
      this.descriptionObserver.disconnect()
    },
    methods: {
      isMobile,
      getArticle() {
        this.images = [];
        this.$store.dispatch('articles/getArticle', this.$route.params.id).then(() => {
          this.article = this.$store.getters['articles/getArticle'];
          for (let i = 0; i < this.article.images.length; i++) {
            this.images.push(this.article.images[i]['url']);
          }
          this.imagesFix()
        });
      },
      imagesFix () {
        const target = document.getElementById('article-container');
        const config = {
          attributes: true,
          childList: true,
          subtree: true
        };

        this.descriptionObserver = new MutationObserver(imagesFix);
        this.descriptionObserver.observe(target, config);
      }
    },
    watch: {
      $route(to, from) {
        this.getArticle();
        $(window).scrollTop(0);
      }
    },
    // metaInfo() {
    //   return {
    //     meta: [
    //       {property: 'og:title', content: this.article.title},
    //       {property: 'og:description', content: this.article.description},
    //       {property: 'og:image', content: window.location.origin + this.article.image},
    //       {property: 'og:type', content: 'Article'},
    //       {property: 'og:url', content: window.location.href}
    //     ]
    //   }
    // }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Open+Sans');

  #article-container {
    padding: 30px 0;
    background-color: #F8F8F8;

    .breadcrumb-custom {
      ul {
        li {
          color: #888888;
          font-size: 12px;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }

    .article {
      margin-top: 30px;
      border-radius: 4px;
      margin-bottom: 50px;
      background-color: #ffffff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

      .left-block {
        .image {
          img {
            width: 100%;
            height: auto;
            border-radius: 4px 0 0 0;
          }
        }

        .creators {
          padding-left: 15px;
          color: #888888;
          font-size: 14px;
          margin-right: 5px;
          display: inline-block;
          font-family: 'Open Sans', sans-serif;

          .creators-council, .creators-deputy {
            // padding: 8px 0;

            a {
              color: #888888;
              text-decoration: none;

              &:hover {
                color: #05a081;
              }
            }

            .coma {
              margin-right:5px;
            }
          }
        }
      }

      .right-block {
        padding: 15px;

        .title-block {
          padding-left: 15px;
          display: inline-block;
          width: 100%;
          padding-right: 40px;

          .title {
            color: #222222;
            font-size: 30px;
            font-weight: 700;

          }

          .publication {
            color: #888888;
            font-size: 12px;
            display: inline-block;
          }
        }

        .facebook {
          float: right;
          cursor: pointer;
          padding: 4px 12px;
          position: relative;
          display: inline-block;
          background-color: #f8f8f8;

          .fa-icon {
            path {
              display: none;
            }
          }

          /*.fa-facebook-f {
            top: 8px;
            left: 15px;
            position: absolute;
            pointer-events: none;
          }*/
        }

        .description {
          color: #555555;
          font-size: 14px;
          margin-top: 15px;
          line-height: 24px;
          padding-left: 15px;
          padding-right: 42px;
          font-family: 'Open Sans', sans-serif;
        }

        .attach-files {
          width: 100%;
          padding-left: 15px;

          .file {
            align-items: center;
            margin-bottom: 15px;
            display: inline-flex;

            .file-link {
              text-decoration: none;

              .file-image {
                margin-right: 10px;
                display: inline-block;

                img {
                  width: 43px;
                  height: 49px;
                }
              }

              .file-title {
                color: #555555;
                font-size: 14px;
                word-break: break-all;
                display: inline-block;
                font-family: 'Open Sans', sans-serif;
              }

              &:hover {
                .file-title {
                  color: #05a081;
                }
              }
            }
          }
        }
      }

      .attach-images {
        #blueimp-gallery {
          .prev, .next, .close {
            color: white;
          }
        }

        .image {
          float: left;
          margin: 5px;
          width: 190px;
          height: 190px;
          cursor: pointer;
          background-size: cover;
          border: 1px solid #ebebeb;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
    }

    hr {
      padding: 15px 0;
    }
  }
  @media screen and (max-width: 1024px) {
    #article-container {
      padding: 11px 0 0 0;
      .container{
        padding: 0 10px;
        .article{
          margin-top: 20px;
          margin-bottom: 20px;
          
          .left-block {

            .creators {
              padding-left: 10px;
              padding-top: 10px;
              font-size: 12px;
            }

            .image {
              img {
                padding: 20px 10px 0 10px;
              }
            }
          }

          .right-block {
            padding-top: 30px;
            padding-bottom: 0;
            .title-block {    
              padding: 0 10px;
              .title{
                font-size: 24px;
                line-height: 33px;
                vertical-align: top;
              }
            }
            .description {    
              padding: 0 10px;
              text-align: left;
            }
            .title-public{
              margin: 10px 0 5px 0;
            }
            .facebook {
              float: none;
              margin-left:26px;
            }
          }
        }
        .container-hr{
          margin:0 !important;
          padding: 0;
        }
      }
      
    }
    
  }
  @media screen and (max-width:767px) {
    .container-fluid{
      padding: 0 10px;
    }
  }
</style>
