<template lang="pug">
  .container-fluid.list-articles
    .row
      template(v-for="article in articles")
        .col-md-4.article
          .article-header
            router-link(:to="'/article/' + article.id" class="title" v-html="article.title")
            .publication-date(v-html="article.createdAt")
          .article-body
            router-link(:to="'/article/' + article.id" tag="div" class="article-img")
              img(:src="article.image")
            .article-description(v-html="article.shortDescription.slice(0, 190)")
            tags(:tags="article.tags")
</template>

<script>
  import tags from '../articles/Tags'

  export default {
    name: "ArticlesList",
    components: {
      tags
    },
    data() {
      return {
        articles: []
      }
    },
    created() {
      this.getArticles();
    },
    methods: {
      getArticles() {
        this.$store.dispatch('articles/getArticlesList', 64).then(() => {
          this.articles = this.$store.getters['articles/getArticlesList'];
        });
      }
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Open+Sans');

  .list-articles {
    .article {
      padding: 0;
      flex: 0 0 32%;
      margin: 0 auto;
      border-radius: 4px;
      padding-bottom: 15px;
      background-color: #ffffff;

      .article-header {
        .title {
          width: 79%;
          height: 65px;
          color: #222222;
          font-size: 16px;
          line-height: 1.2;
          display: inline-block;
          text-decoration: none;
          padding: 16px 0 15px 19px;
          font-family: 'Open Sans - Semibold', sans-serif;
        }

        .publication-date {
          float: right;
          color: #888888;
          font-size: 12px;
          padding-top: 15px;
          padding-right: 10px;
          display: inline-block;
          font-family: 'Open Sans', sans-serif;
        }
      }

      .article-body {
        .article-img {
          img {
            width: 100%;
            height: auto;
          }
        }

        .article-description {
          height: 120px;
          color: #555555;
          font-size: 14px;
          line-height: 20px;
          padding-top: 10px;
          padding-left: 15px;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .list-articles {
      .row{
        display: block;
        padding-bottom: 20px;
        .article {
          flex: 0 0 48%;
          padding-bottom: 20px;
          .article-header {
            .title {
              width: 68%;
              padding: 10px;
              font-size: 15px;
            }
            .publication-date{
              padding: 22px 10px 0 0;
            }
          }

          .article-body {
            .article-img {
              img {
                padding-top: 10px;
              }
            }
            .article-description {
              height: auto;
              padding: 15px 10px 0 10px;
              
            }
          }
        }

        .article:nth-child(3) {
          display: none;
        }
      }
      
    }
  }
</style>
