<template lang="pug">
  .company-product.criteria-evaluations
    reporting-periods( :title="isError === false ? criteriaEvaluations.title : false" v-on:dataByYear="criteriaEvaluationsRequest")
    template(v-if="!isError")
      //.table-part
        // #wrapper_xlsx(v-html="file")
      tablePreview( :file="criteriaEvaluations.table" :classWithYears="true" v-if="criteriaEvaluations.table")
      .download-file
        button.btn.download-button(v-on:click="downloadFile") Завантажити набір
    template(v-else)
      .preview-title
        | {{ isError }}
</template>

<script>
  import XLSX from 'xlsx'
  import reportingPeriods from '../company_products/ReportingPeriods.vue'
  import tablePreview from '../../layout/table_preview/TablePreview'

  export default {
    name: "CriteriaEvaluations",
    components: {
      reportingPeriods,
      tablePreview
    },
    data() {
      return {
        file: '',
        isError: false,
        // criteriaEvaluations: {},
        year: new Date().getFullYear()
      }
    },
    created() {
      if (this.$route.params.id) {
        this.criteriaEvaluationsRequest();
      }
    },
    watch: {
      $route(to, from) {
        this.criteriaEvaluationsRequest(this.year);
      }
    },
    computed: {
      criteriaEvaluations () {
        return this.$store.getters['companyProducts/getCriteriaEvaluations'];
      }
    },
    methods: {
      criteriaEvaluationsRequest(year) {
        if (this.$route.params.id !== "index") {
          if (year) {
            this.year = year;
          } else {
            this.year = this.year - 1;
          }
          let params = { year: this.year, id: this.$route.params.id };
          this.$store.dispatch('companyProducts/getCriteriaEvaluations', params).then(() => {
            this.isError = false;
            this.getFile();
          }).catch(() => {
            this.isError = this.$store.getters['companyProducts/getError'];
          });
        }
      },
      downloadFile() {
        let link = document.createElement('a');
        link.href = this.criteriaEvaluations.file_url;
        link.setAttribute('download', this.criteriaEvaluations.file_name);
        document.body.appendChild(link);
        link.click();
      },
      getFile() {
        let that = this;
        fetch(this.criteriaEvaluations.file_url).then(function (response) {
          return response.arrayBuffer();
        }).then(function (array_buffer) {
          let data = new Uint8Array(array_buffer);
          let wb = XLSX.read(data, {type: 'array'});
          that.file = XLSX.write(wb, {type: 'string', bookType: 'html'});
        });
      }
    }
  }
</script>
