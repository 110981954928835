import axios from "axios"
let qs = require('qs');

var numeralCodes = [
  ["", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
  ["", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC"],
  ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM"],
];
function convertToRome(num) {
  let numeral = "";
  let digits = num
    .toString()
    .split("")
    .reverse();
  for (let i = 0; i < digits.length; i++) {
    numeral = numeralCodes[i][parseInt(digits[i])] + numeral;
  }
  return numeral;
}

const actions = {
  getDeputies(context, payload) {
    let query = payload;
    return new Promise((resolve, reject) => {
      axios.get('/api/deputies.json', {
        params: {
          query: query
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      }).then(response => {
        let deputies = response.data.deputies;
        let convocation = response.data.convocation_number;
        if (convocation != null) { 
          context.commit('SET_CONVOCATION_NUMBER', convertToRome(convocation));
        }
        context.commit('SET_DEPUTIES', deputies);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  },
  getFilteredDeputies(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/deputies_by.json', {
        params: {
          query: payload
        },
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      }).then(response => {
        const deputies = response.data.deputies
        context.commit('SET_FILTERED_DEPUTIES', deputies);
        resolve();
      }).catch(error => {
        return reject(error.response.data.error);
      })
    })
  },
  getDeputy(context, payload) {
    let id = payload;
    return new Promise((resolve, reject) => {
      axios.get('/api/deputy/' + id + '.json').then(response => {
        let deputy = response.data.deputy;
        context.commit('SET_DEPUTY', deputy);
        resolve();
      }).catch(errors => {
        reject(errors);
      })
    })
  },
  getFractions(context) {
    return new Promise((resolve, reject) => {
      axios.get('/api/fractions.json').then(response => {
        resolve(response);
      }).catch(error => {
        return reject(error.response.data.error);
      })
    })
  },
  getParts(context) {
    return new Promise((resolve, reject) => {
      axios.get('/api/parts.json').then(response => {
        resolve(response);
      }).catch(error => {
        return reject(error.response.data.error);
      })
    })
  },
};

const mutations = {
  SET_DEPUTIES(context, payload) {
    context.deputies = payload;
  },
  SET_FILTERED_DEPUTIES(context, payload) {
    context.filteredDeputies = payload;
  },
  SET_DEPUTY(context, payload) {
    context.deputy = payload;
  },
  SET_CONVOCATION_NUMBER(context, payload) {
    context.convocation_number = payload;
  }
};

const state = {
  deputies: [],
  filteredDeputies: [],
  deputy: [],
  convocation_number: 'IX'
};

const getters = {
  getDeputies(state) {
    return state.deputies
  },
  getFilteredDeputies(state) {
    return state.filteredDeputies
  },
  getDeputy(state) {
    return state.deputy
  },
  getConvocationNumber(state) {
    return state.convocation_number
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}
