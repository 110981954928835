import axios from "axios"

const actions = {
  getCriteriaValues(context, payload) {
    let year = payload.year;
    let id = payload.deputy_id;
    return new Promise((resolve, reject) => {
      axios.get('/api/criteria_values.json', {
        params: {
          year: year,
          deputy_id: id
        }
      }).then(response => {
        let criteriaValues = response.data;
        context.commit('SET_CRITERIA_VALUES', criteriaValues);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  },
  getUniqTotalValues(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/total_values.json').then(response => {
        let uniqTotalValues = response.data.total_values;
        context.commit('SET_UNIQ_TOTAL_VALUES', uniqTotalValues);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  }
};

const mutations = {
  SET_CRITERIA_VALUES(context, payload) {
    context.criteriaValues = payload;
  },
  SET_UNIQ_TOTAL_VALUES(context, payload) {
    context.uniqTotalValues = payload;
  }
};

const state = {
  criteriaValues: [],
  uniqTotalValues: []
};

const getters = {
  getCriteriaValues(state) {
    return state.criteriaValues
  },
  getUniqTotalValues(state) {
    return state.uniqTotalValues
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}
