export default function () {
  let parentEl = document.getElementById('description')
  let parentWidth = parentEl.offsetWidth
  let images = parentEl.getElementsByTagName('img')
  for(let i = 0; i < images.length; i++) {
    if (parentWidth < parseInt(images[i].style.width)) {
      images[i].style.width = "100%"
      images[i].style.height = "auto"
    }
  }
}
