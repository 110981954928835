<template lang="pug">
  .container-fluid
    .row.deputy-info
      .col-sm-5.col-md-3.px-0
        .deputy-image
          img(:src="deputy.image")
      .col-sm-7.col-md-9.px-0
        .deputy-name(v-html="deputy.full_name")
        .fraction-part
          template( v-if="isMobile()")
            router-link.show-deputy-link( :to="`/councils?fraction_id=${deputy.fraction_id}`")
              .fraction(v-html="deputy.fraction")
            template(v-if="deputy.fraction != deputy.part")
              br
              router-link.show-deputy-link(:to="`/councils?part_id=${deputy.part_id}`")
                .part(v-html="deputy.part")
          template(v-else)
            | Фракція:
            router-link.show-deputy-link( :to="`/councils?fraction_id=${deputy.fraction_id}`")
              .fraction(v-html="deputy.fraction")
            .separator
            | Партія:
            router-link.show-deputy-link( :to="`/councils?part_id=${deputy.part_id}`")
              .part(v-html="deputy.part")
        template( v-if="isMobile()")
          .separator
        .council-wrap
          template( v-if="!isMobile()")
            | Міська рада:
          router-link.show-deputy-link( :to="`/councils?council_id=${deputy.council_id}`")
            .council(v-html="deputy.council")
          
        .deputy-text(v-html="deputy.short_biography")
        .short-bio(v-if="deputy.bio != null")
          p(v-if="deputy.bio.birthday")
            b Дата народження: 
            | {{ prepareDate(deputy.bio.birthday) }}
          p(v-if="deputy.bio.education")
            b Освіта: 
            | {{ deputy.bio.education }}
          p(v-if="deputy.bio.experience")
            b Досвід роботи: 
            | {{ deputy.bio.experience }}
          p(v-if="deputy.bio.family_status")
            b Місце роботи: 
            | {{ deputy.bio.family_status }}
          p(v-if="deputy.bio.work")
            b Сімейний стан: 
            | {{ deputy.bio.work }}
</template>

<script>
import { mapGetters } from 'vuex'
import {isMobile} from '../../utils/mobileDetect'
export default {
  name: 'DeputyInfo',
  computed: {
    ...mapGetters({
      deputy: 'deputies/getDeputy'
    })
  },
  methods: {
    isMobile,
    prepareDate(str) {
      if (str.length > 10 ) return str
      if (this.$luxon) {
        return this.$luxon(str)
      }
    }
  }
}
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.deputy-info {
  padding: 30px;
  margin-top: 30px;
  border-radius: 4px;
  margin-bottom: 30px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

  .deputy-image {
    width: 100%;

    img {
      width: 100%;
      height: auto;
      border-radius: 50%;
    }
  }

  .deputy-name {
    color: #222222;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
  }

  .fraction-part {
    margin-top: 16px;
  }

  .fraction-part,
  .council-wrap {
    font-weight: bold;
    color: #555555;
  }

  .fraction,
  .part,
  .council {
    margin-left: 5px;
    color: #000000;
    font-size: 18px;
    font-weight: normal;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;

    &:hover {
      color: #05a081;
    }
  }

  .separator {
    width: 1px;
    height: 15px;
    opacity: 0.2;
    margin: 0 25px;
    display: inline-block;
    background-color: #222222;
  }

  .council-wrap {
    margin-bottom: 16px;
  }

  .council {
    display: inline-block;
  }

  .deputy-text,
  .short-bio {
    color: #555555;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Open Sans', sans-serif;

    p {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1024px) {
  .deputy-info {
    .deputy-name {
      font-size: 20px;
    }

    .fraction-part {
      .fraction,
      .part {
        font-size: 16px;
      }
    }

    .council-wrap {
      .council {
        font-size: 16px;
      }
    }

    .deputy-text {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 991px) {
  .deputy-info {
    .deputy-name {
      font-size: 16px;
    }

    .fraction-part {
      .fraction,
      .part {
        font-size: 14px;
      }
    }

    .council-wrap {
      .council {
        font-size: 14px;
      }
    }

    .deputy-text {
      font-size: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .deputy-info {
    padding: 20px 10px!important;
    margin-bottom: 20px;
    margin-top: 20px;
    .deputy-image {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      img {
        width: 113.5px;
        height: 113.5px;
        border-radius: 50%;
        border-color: transparent;
      }
    }

    .deputy-name {
      text-align: center;
      color: #222222;
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      margin-bottom: 15px;
    }

    .council-wrap {
      margin-bottom: 20px;
    }

    .fraction-part {
      margin:  0 0 10px 0;
    }

    .fraction-part,
    .council-wrap {
      font-size: 15px;
      display: block;
      text-align: center;

      .fraction,
      .part,
      .council {
        color: #000000;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        display: inline-block;
      }

      
    }
    .separator {
      height: 0;
      width: 100%;
      border: .5px solid #eee;
      opacity: unset;
      background: transparent;
      margin: 0 0 10px 0;
    }

    .deputy-text {
      color: #555555;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
</style>
