<template lang="pug">
  .container-fluid.deputy-blocks
    .row
      .col-md-7(v-if="!isMobile()")
        .one-block.first
          .title
            | Графік роботи
          .separator
          #wrapper-file(v-html="file")
          .separator
          a.view-on-map(:href="map_url")
            | Дивитись на карті
            img(:src="arrow")
      .col-md-5(:class="{'px-0': isMobile()}")
        .one-block.rec
          .title
            | Персональні рекомендації
          .separator
          .recommendation
            .row
              template(v-for="recommendation in deputy.recommendations")
                .recommendation-block.col-12
                  a.recommendation-link(:href="recommendation.url" target="_blank")
                    .recommendation-image
                      img(:src="pdf")
                    .recommendation-title(v-html="recommendation.title")
      .col-12.one-block.two
        .mark(v-html="deputy.total")
        .title
          | Загальна оцінка
        <!--.zoom(@click="zoomChart")-->
        <!--img(:src="zoom")-->
        .separator
        <!--#prev-btn(@click="prevYear")-->
        #reporting_periods
          template(v-for="reporting_period in deputy.reporting_periods")
            .reporting-period-title(
              v-html="reporting_period.year"
              @click="getChartByYear(reporting_period.year)"
              :class="{active: activeYear(reporting_period.year)}"
            )
        <!--#next-btn(@click="nextYear")-->
        MobileChart( v-if="isMobile()" :deputy="deputy")
        DesktopChart( v-else :deputy="deputy")
      
      .col-md-7.d-lg-block(:class="{'px-0': isMobile()}")
        .one-block.first.reports
          template
            .title
              | Звіти депутата
            //- .separator
            .table-wrapper
              table.table.table-hover.table-striped
                thead
                  tr
                    th(scope='col')
                    column-head( :object="sortFullNameObject" :ref="sortFullNameObject.alias")
                    template( v-for="reportingPeriod in reportingPeriods")
                      th.reporting-period-row
                        span
                          | {{ reportingPeriod.year }}
                tbody
                  tr
                    td
                      img.deputy-image( :src="deputy.image")
                    td
                      | {{ deputy.full_name }}
                    template( v-for="period in reportingPeriods" v-if="reportingPeriods")
                      td.check-marks-row
                        template
                          span.check-marks
                            i.fas.fa-check( v-if=" deputy.deputy_reports.map(x => x.reporting_period_id).includes(period.id)")
                            i.fas.fa-times( v-else)
                          a.download-report-link( v-if="fileForDownload(period.id)" :href="fileForDownload(period.id).file.url" download)
                            i.far.fa-file-word
                    template( v-if="!deputy.reporting_periods.length")
                      td.check-marks-row
                        span.check-marks
                          i.fas.fa-times
            .separator
            a.view-on-map(:href="'/deputies_more/deputies_reports/' + deputy.council_id")
                | Звіти депутатів з {{deputy.council}}
                img(:src="arrow")
      .col-md-5.d-none.d-lg-block
        .one-block.rec
          template
            .title
              | Історія діяльності
            .separator
          .one-history
            ul
              li(v-for="interval in historyDeputy" v-if="historyDeputy")
                .line
                  |  &ensp; 
                .time-range 
                  |  {{timeRangeForHistory(interval)}}
                .part-name 
                  |  {{interval.partName}}
            .separator(v-if="isMobile()")
</template>

<script>
import columnHead from '../home/ColumnHeader'
import XLSX from 'xlsx'
import MobileChart from './mobile/Chart'
import DesktopChart from './desktop/Chart'
import { mapGetters } from 'vuex'
import { isMobile } from '../../utils/mobileDetect'
export default {
  name: 'DeputyData',
  components: {
    MobileChart,
    DesktopChart,
    columnHead
  },
  externals: {
    moment: 'moment'
  },
  data() {
    return {
      // hard code, no data with api
      nameMonth: [
        'Січень',
        'Лютий',
        'Березень',
        'Квітень',
        'Травень',
        'Червень',
        'Липень',
        'Серпень',
        'Вересень',
        'Жовтень',
        'Листопад',
        'Грудень'
      ],
      historyDeputy: [
        {
          startTime: new Date(2011, 1, 28),
          endTime: new Date(2013, 1, 28),
          partName: 'БПП Солідарність'
        },
        {
          startTime: new Date(2013, 1, 28),
          endTime: new Date(2014, 1, 28),
          partName: 'БПП Солідарність'
        },
        {
          startTime: new Date(2014, 1, 28),
          endTime: new Date(2018, 1, 28),
          partName: 'БПП Солідарність'
        },
        {
          startTime: new Date(2018, 1, 28),
          endTime: new Date(2020, 1, 28),
          partName: 'БПП Солідарність'
        }
      ], // end hard code
      sortFullNameObject: {
        name: "Ім'я депутата",
        alias: 'full_name'
      },
      reportingPeriods: [],
      open: false,
      deputy: {},
      file: '',
      year: new Date().getFullYear() - 1
    }
  },
  computed: {
    ...mapGetters({
      criteriaValuesGetter: 'criteriaValues/getCriteriaValues',
      deputyGetter: 'deputies/getDeputy'
    }),
    arrow() {
      return require('@/arrow-right.svg')
    },
    zoom() {
      return require('@/zoom.png')
    },
    pdf() {
      return require('@/pdf.png')
    },
    map_url() {
      return '/deputies_more/reception_maps/' + this.deputy.council_id
    }
  },
  created() {
    this.getReportingPeriods()
    this.deputy = this.deputyGetter
    this.year = this.deputy.reporting_periods[0].year
  },
  mounted() {
    this.viewSchedule()
  },
  methods: {
    isMobile,
    timeRangeForHistory(interval) {
      return (
        this.nameMonth[interval.startTime.getUTCMonth()] +
        ' ' +
        interval.startTime.getUTCFullYear() +
        ' - ' +
        this.nameMonth[interval.endTime.getUTCMonth()] +
        ' ' +
        interval.endTime.getUTCFullYear()
      )
    },
    fileForDownload(period_id) {
      let result = this.deputy.deputy_reports.filter(x => {
        return x.reporting_period_id == period_id
      })
      return result[0]
    },
    getReportingPeriods() {
      this.$store.dispatch('reportingPeriods/getReportingPeriod').then(() => {
        this.reportingPeriods = this.$store.getters[
          'reportingPeriods/getReportingPeriods'
        ]
      })
    },
    viewSchedule() {
      let that = this
      fetch(this.deputy.schedule)
        .then(function(response) {
          return response.arrayBuffer()
        })
        .then(function(arraybuffer) {
          let data = new Uint8Array(arraybuffer)
          let wb = XLSX.read(data, { type: 'array' })
          that.file = XLSX.write(wb, { type: 'string', bookType: 'html' })
          this.$emit('file', that.file)
        })
        .catch(function() {
          that.file = 'Файл для данного депутата не завантажено'
        })
    },
    toggleTable() {
      this.open = !this.open
    },
    prevYear() {
      document.getElementById('reporting_periods').scrollLeft -= 46
    },
    nextYear() {
      document.getElementById('reporting_periods').scrollLeft += 46
    },
    getChartByYear(year) {
      if (this.year === year) return
      this.year = year
      let params = {
        year: this.year,
        deputy_id: this.deputy.id
      }
      this.$store
        .dispatch('criteriaValues/getCriteriaValues', params)
        .then(() => {
          console.log('RESPONSE', this.criteriaValuesGetter.criteria_data)
          this.deputy.criteria_values = this.criteriaValuesGetter.criteria_data.criteria_values
          this.deputy.criteria_colors = this.criteriaValuesGetter.criteria_data.criteria_colors
          this.deputy.criteria_explanations = this.criteriaValuesGetter.criteria_data.criteria_explanations
          this.deputy.criteria_names = this.criteriaValuesGetter.criteria_data.criteria_names
        })
    },
    activeYear(year) {
      return this.year === year
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
.one-history {
  ul {
    font-family: 'Open Sans', sans-serif;
    height: 218px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 40px;
    padding-top: 15px;
    position: relative;
    list-style-image: url('~assets/images/ellipse.png');
    margin: 0;
  }
  li {
    padding-left: 10px;
    .part-name {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 15px;
    }
    .time-range {
      font-size: 16px;
      font-weight: 300;
    }
  }
  .line {
    border-left: 1px solid #eeeeee;
    position: absolute;
    margin-left: -23px;
    margin-top: 25px;
  }
}
.deputy-blocks {
  margin-bottom: 50px;
  .reports {
    padding: 0 10px !important;
    .table-wrapper {
      overflow-x: auto;
      .table-striped {
        margin-bottom: 3rem;
      }
      thead {
        th {
          &.reporting-period-row {
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          th {
            font-weight: normal;
          }

          td {
            vertical-align: middle;

            &.check-marks-row {
              text-align: center;

              .check-marks {
                .fa-check {
                  color: green;
                }

                .fa-times {
                  color: red;
                }
              }

              .download-report-link {
                color: #3079ef;
                display: block;
                cursor: pointer;
              }
            }

            .show-deputy {
              color: #212529;
            }

            .deputy-image {
              width: 40px;
              height: 40px;
            }
          }
        }
      }
    }
  }
  .one-block {
    padding: 0;
    border-radius: 4px;
    /*margin-bottom: 30px;*/
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

    .title {
      padding: 15px;
      color: #000000;
      font-size: 18px;
      text-transform: uppercase;
      font-family: 'Open Sans - Semibold', sans-serif;
    }

    .separator {
      width: 100%;
      height: 1px;
      background-color: #eeeeee;
    }

    #wrapper-file,
    #wrapper-file-mobile {
      padding: 15px;
      height: 170px;
      overflow: auto;
      color: #4e4e4e;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;

      table {
        tbody {
          tr {
            td {
              padding: 7px 0;
            }
          }
        }
      }
    }

    canvas {
      margin: 10px auto;
      width: 100% !important;
      height: auto !important;
    }

    .view-on-map {
      display: inline-block;
      height: 37px;
      margin-top: 12px;
      color: #222222;
      font-size: 14px;
      padding-left: 15px;
      text-decoration: none;
      text-transform: uppercase;
      font-family: 'Open Sans', sans-serif;

      img {
        padding-left: 10px;
      }
    }

    .recommendation-block {
      margin: 10px;
      padding-right: 44px;

      .recommendation-link {
        display: flex;
        align-items: center;
        text-decoration: none;

        .recommendation-image {
          margin-right: 10px;
          display: inline-block;

          img {
            width: 43px;
            height: 49px;
          }
        }

        .recommendation-title {
          min-width: 275px;
          color: #555555;
          font-size: 14px;
          display: inline-block;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }

    .recommendation {
      height: 221px;
      overflow: auto;
      overflow-x: hidden;
      position: relative;
    }
  }

  .first {
    /*flex: 0 0 60%;*/
    margin-left: -15px;
    min-height: 278px;

    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      opacity: 0.4;
      background-color: lightgrey;
    }

    ::-webkit-scrollbar-thumb:vertical {
      opacity: 0.8;
      background-color: #4d4d4d;
    }

    ::-webkit-scrollbar-thumb:horizontal {
      opacity: 0.8;
      background-color: #4d4d4d;
    }

    ::-webkit-scrollbar-thumb:vertical:hover {
      opacity: 0.8;
      background-color: #4d4d4d;
    }
  }

  .two {
    flex: 0 0 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    margin-top: 20px;
    .mark {
      width: 50px;
      height: 55px;
      color: #ffffff;
      font-size: 16px;
      padding-top: 15px;
      margin-right: 10px;
      text-align: center;
      display: inline-block;
      border-radius: 4px 0 0 0;
      background-color: #ff9999;
      font-family: 'Open Sans', sans-serif;
    }
    
    #prev-btn {
      float: left;
      padding: 0 5px;
      cursor: pointer;
      display: inline-block;

      &:before {
        content: '\2039';
      }
    }

    #next-btn {
      float: right;
      padding: 0 5px;
      cursor: pointer;
      display: inline-block;

      &:before {
        content: '\203A';
      }
    }

    #reporting_periods {
      width: 90%;
      padding: 0 10px;
      position: relative;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      display: inline-block;

      .reporting-period-title {
        width: 46px;
        padding: 0 9px;
        color: #4e4e4e;
        font-size: 12px;
        cursor: pointer;
        display: inline-block;
        font-family: 'Open Sans', sans-serif;
      }

      .reporting-period-title:first-child {
        padding-left: 0;
      }

      .reporting-period-title:last-child {
        padding-right: 0;
      }

      .active {
        width: 46px;
        color: #05a081;
        font-size: 14px;
        font-weight: bold;
        font-family: 'Open Sans - Semibold', sans-serif;
      }
    }

    .title {
      padding: 0;
      display: inline-block;
    }

    .zoom {
      float: right;
      margin: 15px;
      padding: 0 5px;
      cursor: pointer;
      display: inline-block;
      background-color: #f8f8f8;
    }

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .rec {
    /*flex: 0 0 40%;*/
    margin-right: -15px;
    margin-left: -15px;
    height: 278px;

    ::-webkit-scrollbar {
      width: 4px;
      height: 10px;
    }

    ::-webkit-scrollbar-track {
      opacity: 0.4;
      background-color: lightgrey;
    }

    ::-webkit-scrollbar-thumb:vertical {
      opacity: 0.8;
      background-color: #4d4d4d;
    }

    ::-webkit-scrollbar-thumb:vertical:hover {
      opacity: 0.8;
      background-color: #4d4d4d;
    }
  }
}

@media screen and (max-width: 1024px) {
  .deputy-blocks {
    .one-block {
      .title {
        font-size: 16px;
      }

      #wrapper-file,
      #wrapper-file-mobile {
        padding: 10px;

        table {
          tbody {
            tr {
              td {
                padding: 5px 0;
              }
            }
          }
        }
      }
    }

    .first {
      margin-right: 10px;
    }

    .rec {
      .recommendation-block {
        .recommendation-image {
          img {
            width: 42px;
            height: 47px;
          }
        }

        .recommendation-title {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .deputy-blocks {
    .one-block {
      .title {
        font-size: 12px;
      }

      #wrapper-file,
      #wrapper-file-mobile {
        padding: 10px;
        height: 175px;
      }
    }

    .two {
      #reporting_periods {
        width: 85%;
      }
    }

    .rec {
      .recommendation-block {
        .recommendation-image {
          img {
            width: 30px;
            height: 35px;
          }
        }

        .recommendation-title {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .deputy-blocks {
    margin: 0;

    .deputy-data-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      text-transform: uppercase;
      width: 100%;
      height: 62px;
    }

    .fa-chevron-down {
      transition: all 0.2s linear;
      color: #555555;
    }

    .one-block {
      flex: none;
      padding-bottom: 16px;
      margin-bottom: 20px;
      &.rec {
        width: 100%;
        margin: 0;
        height: auto; 
        padding: 20px 10px 0;
      }

      &.first {
        min-height: unset;
        margin: 0 0 20px 0;
        padding: 20px 10px 0;
        .deputy-data-header {
          height: auto;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          margin: 0 0 20px 0;
          padding: 0;
        }
        .deputy-data-body {
          padding: 0;
          #wrapper-file-mobile {
            height: auto;
            border-top: 1px solid #eeeeee;
            margin: 0;
            padding: 20px 0;
          }
        }
      }

      

      &.two {
        .title {
          margin-bottom: 15px;
        }
        .separator {
          margin-bottom: 0;
        }
      }

      .title {
        line-height: 22px;
        font-size: 16px;
        font-weight: 600;
        padding: 0;
        margin-bottom: 20px;
      }

      .separator {
        margin-bottom: 20px;
      }
      .recommendation {
        height: auto;
      }

      .recommendation-block {
        margin: 0 0 20px 0;
        padding-right: 15px;
        .recommendation-link {
          align-items: flex-start;
          justify-content: space-between;
          .recommendation-image {
            width: 36.75px;
            height: 42px;
            img {
              width: inherit;
              height:inherit;
            }
          }
          .recommendation-title {
            line-height: 16px;
             min-width: auto;
          }
        }
      }



      #wrapper-file {
        border-top: 1px solid #eeeeee;
        height: min-content;
        padding-left: 0;
        padding-right: 0;
        margin: 0 10px;
      }

      .view-on-map {
        height: auto;
        position: unset;
        margin: 0 0 20px 0;
        padding: 0;
      }

      #reporting_periods {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:center;
        padding: 35px 18.5px;
        .reporting-period-title {
          font-size: 12px;
          line-height: 16px;
          padding: 0;
          margin: 0 16px;
          text-align: center;
          &:first-of-type,
          &:last-of-type {
            margin: 0;
          }
          &.active {
            font-size: 15px;
            line-height: 20px;
          }
        }
      }

      
    }
    .reports {
      padding-top: 20px !important;
      .title {
        margin-bottom: 20px;
      }
      .table-wrapper,
      .table-wrapper .table-striped {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
