<template lang="pug">
  .container-fluid#personal-pages
    .row.personal-pages-header
      .container
        .row
          .col-12
            Breadcrumbs
    .container
      .row
        .col-md-4.input-block
          v-select.form-control.custom-select( :options="councils" label="title" placeholder="Всі ради" v-model="sortingByCouncils")
        .col-md-4.input-block
          v-select.form-control.custom-select( :options="fractions" label="title" placeholder="Всі фракції" v-model="sortingByFractions")
        .col-md-4.input-block
          v-select.form-control.custom-select( :options="parts" label="title" placeholder="Всі партії" v-model="sortingByParts")
      .row.councils-block.d-lg-flex
        .col-12
          template
            SingleCouncil(:allDeputies="allDeputies" :council="setConcil()" :councilWebsite="setCouncilWebsite()")
</template>

<script>
import Breadcrumbs from '../layout/Breadcrumbs'
import SingleCouncil from './councils/Council'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  name: 'PersonalPages',
  components: {
    Breadcrumbs,
    SingleCouncil,
    'v-select': vSelect
  },
  data() {
    return {
      sortingByCouncils: {},
      sortingByParts: null,
      sortingByFractions: null,
      councils: [],
      timerDelay: 200,
      timer: null,
      allDeputies: [],
      fractions: [],
      parts: [],
      filteredQuery: {
        council: [],
        part_id: '',
        fraction_id: ''
      }
    }
  },
  watch: {
    query: {
      handler(oldValue, newValue) {
        this.sendQueryWithDelay()
      },
      deep: true
    },
    sortingByCouncils(val) {
      val
        ? (this.filteredQuery.council = [val.id])
        : (this.filteredQuery.council = [])
      this.getDeputies()
    },
    sortingByParts(val) {
      val
        ? (this.filteredQuery.part_id = val.id)
        : (this.filteredQuery.part_id = '')
      this.getDeputies()
    },
    sortingByFractions(val) {
      val
        ? (this.filteredQuery.fraction_id = val.id)
        : (this.filteredQuery.fraction_id = '')
      this.getDeputies()
    }
  },
  computed: {
    ...mapGetters({
      councilsGetter: 'councils/getCouncils'
    }),
    backgroundImage() {
      return require('@/body_top_background.png')
    }
  },
  created() {
    const query = this.$route.query
    query.council_id ? (this.filteredQuery.council = [query.council_id]) : null
    query.fraction_id
      ? (this.filteredQuery.fraction_id = query.fraction_id)
      : null
    query.part_id ? (this.filteredQuery.part_id = query.part_id) : null
    this.getCouncils()
    this.getDeputies()
    this.getAllFractions()
    this.getAllParts()
  },
  methods: {
    ...mapActions({
      currentCouncilIdAction: 'councils/setCurrentCouncilId',
      getCouncilsAction: 'councils/getCouncils',
      getFractions: 'deputies/getFractions',
      getParts: 'deputies/getParts'
    }),
    getCouncils() {
      this.getCouncilsAction().then(() => {
        this.councils = this.councilsGetter
        if (this.$route.query.council_id) {
          this.sortingByCouncils = this.councils.find(
            item => item.id === Number(this.$route.query.council_id)
          )
        }
      })
    },
    getAllFractions() {
      this.getFractions().then(response => {
        this.fractions = response.data
        if (this.$route.query.fraction_id) {
          this.sortingByFractions = this.fractions.find(
            item => item.id === Number(this.$route.query.fraction_id)
          )
        }
      })
    },
    getAllParts() {
      this.getParts().then(response => {
        this.parts = response.data
        if (this.$route.query.part_id) {
          this.sortingByParts = this.parts.find(
            item => item.id === Number(this.$route.query.part_id)
          )
        }
      })
    },
    async getDeputies() {
      await this.$store.dispatch(
        'deputies/getFilteredDeputies',
        this.filteredQuery
      )
      this.allDeputies = this.$store.getters['deputies/getFilteredDeputies']
    },
    setConcil() {
      return this.sortingByCouncils ? this.sortingByCouncils.title : ''
    },
    setCouncilWebsite(){
      return this.sortingByCouncils ? this.sortingByCouncils.website : ''
    }
  }
}
</script>

<style lang="scss">
#personal-pages {
  background-color: #f8f8f8;
  padding-bottom: 40px;

  .input-block {
    margin-bottom: 50px;

    .input-group {
      box-shadow: 0 2px 3px rgba(23, 2, 37, 0.15);
      border-radius: 4px;

      .desktop {
        display: none;
      }

      .form-control {
        border: none;
      }

      .input-group-append {
        .input-group-text {
          border: none;
        }
      }
    }

    .input-group.mobile {
      display: none;
    }

    .search {
      display: none;
    }

    input.form-control {
      padding: 8px;
      font-size: 14px;

      &:focus {
        outline: 0 !important;
        border-color: initial;
        box-shadow: none;
      }
    }

    .custom-select {
      padding: 0;
      border: none;
      background-color: #fff;
      background-image: none;
      font-size: 14px;
      box-shadow: 0 2px 3px rgba(23, 2, 37, 0.15);

      .dropdown-toggle {
        border: none;
        padding: 0;

        &:after {
          display: none;
        }

        .vs__actions {
          .clear {
            font-size: 18px;
          }

          .open-indicator {
            padding-bottom: 1px;

            &:before {
              border-width: 2px 2px 0 0;
              height: 6px;
              width: 6px;
            }
          }
        }

        .vs__selected-options {
          padding: 0;

          input[type='search'] {
            margin: 0;
            border: none;
            padding-left: 16px;
          }

          .selected-tag {
            height: 100%;
            margin: 0;
            padding-left: 15px;
          }
        }
      }

      .dropdown-menu {
        top: 30px;
        border: none;

        li {
          * {
            font-size: 14px;
          }
        }

        a {
          &:hover {
            background-color: grey;
          }
        }

        .highlight {
          a {
            background-color: grey;
          }
        }
      }
    }
  }

  .breadcrumb-custom ul li {
    color: #888;
    display: flex !important;;
  }

  .personal-pages-header {
    height: 88px;

    .title {
      font-size: 30px;
      text-transform: uppercase;
    }
  }
}
.councils-block-list {
  margin-top: -85px;
}

@media (max-width: 991px) {
  div#personal-pages {
    .personal-pages-header {
      height: 126px;
    }

    .councils-block {
      margin-top: 20px;
    }
  }
}

@media (max-width: 767px) {
  #personal-pages {
    .input-block {
      margin-bottom: 20px;
    }
    .personal-pages-header {
      .title {
        font-size: 22px;
      }
    }
    .container {
      .row.councils-block {
        .councils-block-list {
          padding: 0 10px 0 15px;
        }
      }
    }
  }
}
</style>
