<template lang="pug">
  .breadcrumb.breadcrumb-custom
    ul
      li.linked( @click="$router.push('/')")
        img.logo_home( :src="logo_home" v-if="isMobile()")
        span(v-if="!isMobile()") 
          | Головна
        i.fas.fa-chevron-right.fa-xs.pl-1
        i.fas.fa-chevron-right.fa-xs.pr-1
      li( @click="routeTo" :class="{'linked': !!mainBreadcrumbs.href}")
        | {{ mainBreadcrumbs.title }}
        .showLi(v-show="mainBreadcrumbs.title")
          template( v-if="current || subBreadcrumbs && !isMobile()")
            i.fas.fa-chevron-right.fa-xs.pl-1
            i.fas.fa-chevron-right.fa-xs.pr-1
      li( v-if="current " :title="current")
        | {{ prepareCurrent(current) }}
      li( v-else v-show="!isMobile()")
        | {{ subBreadcrumbs }}
        | {{ menuLinks }}

</template>

<script>
import {isMobile} from '../utils/mobileDetect'
export default {
  name: 'Breadcrumbs',
  props: {
    current: {
      type: String,
      default: ''
    }
  },
  computed: {
    menuLinks() {
      Array.prototype.unique = function() {
        let a = this.concat()
        for (let i = 0; i < a.length; ++i) {
          for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) a.splice(j--, 1)
          }
        }
        return a
      }

      let arr = []
      this.$route.matched.forEach(val => {
        arr = [...arr, ...val.path.split('/').slice(1, 3)].unique()
      })

      if (this.$store.getters['menus/getMenus']) {
        this.$store.getters['menus/getMenus'].forEach(link => {
          if (link.alias === arr[0]) {
            let breadcrumbs = {
              title: link.title,
              href: link.alias === 'articles' || 'councils' ? link.link : ''
            }

            this.$store.dispatch('breadcrumbs/getBreadcrumbsSub', '')
            this.$store.dispatch('breadcrumbs/getBreadcrumbsMain', breadcrumbs)

            if (link.sub_links) {
              if (arr[1] === 'advanced_info') {
                this.$store.dispatch(
                  'breadcrumbs/getBreadcrumbsSub',
                  this.$store.getters['materials/getMaterial'].title
                )
              } else {
                link.sub_menus.forEach(sub_menu => {
                  if (sub_menu.alias === arr[1]) {
                    this.$store.dispatch(
                      'breadcrumbs/getBreadcrumbsSub',
                      sub_menu.title
                    )
                  }
                })
              }
            }
          }
        })
      }
      return ''
    },
    mainBreadcrumbs() {
      return this.$store.getters['breadcrumbs/getBreadcrumbsMain']
    },
    subBreadcrumbs() {
      return this.$store.getters['breadcrumbs/getBreadcrumbsSub']
    },
    logo_home() {
      const path = this.$route.path
      if (path.includes('deputies_more') || path.includes('company_products')) {
        return require('@/logo-home-light.png')
      } else {
        return require('@/logo_home.png')
      }
    }
  },
  methods: {
    isMobile,
    routeTo() {
      if (this.mainBreadcrumbs.href) {
        this.$router.push(this.mainBreadcrumbs.href)
      }
    },
    prepareCurrent(str) {
      if (!this.isMobile()) return str
      return str.length >= 18 ? `${str.slice(0, 15)}...` : str
    }
  
  }
}
</script>

<style lang="scss">
@media (max-width: 991px) {
  div.breadcrumb-custom {
    margin: 25px 0px;
  }

  .linked i {
    margin-top: 2px;
  }
}
@media (max-width: 767px) {
  div.breadcrumb-custom {
    margin: 10px 0px;
    height: 18px;
  }
}
.breadcrumb-custom {
  background: transparent;
  border: none;
  padding: 0px;
  margin: 35px 0px;

  ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      float: left;
      height: 10px;
      width: auto;
      color: white;
      font-size: 12px;
      cursor: default;
      align-items: center;
    }
  }

  .linked {
    cursor: pointer;
    font-size: 12px;
  }
}
</style>
