<template lang="pug">
  .container
    .row.h-100.justify-content-center.align-items-center
      defaultSpiner( :animation-duration="1000" :size="65" color="#05a081")
</template>

<script>
  import { HalfCircleSpinner } from 'epic-spinners'

  export default {
    name: "Spiner",
    components: {
      defaultSpiner: HalfCircleSpinner
    }
  }
</script>

<style scoped lang="scss">
  .container {
    height: 50vh;
  }
</style>