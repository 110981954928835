<template lang="pug">
  .company-product.analytical-reports
    template(v-if="!isError")
      #reporting_periods
        template(v-for="period in reportingPeriods")
          .reporting-period-title(v-html="period.year" @click="changeByYear(period.year)" :class="{active: activeYear(period.year)}")
      #reports
        template(v-for="report in analyticalReports")
          .report-title(v-html="report.title" :class="{active: report.active}" @click="changeByName(report.id)")
      #wrapper_pdf(v-if="showTemplate")
        embed(:src="file_url" width="100%")
      spiner(v-else)
    template(v-else)
      .preview-title
        | {{ isError }}
</template>

<script>
  import spiner from '../../layout/Spiner'

  export default {
    name: "AnalyticalReports",
    components: {
      spiner
    },
    data() {
      return {
        timerDelay: 500,
        isError: false,
        showTemplate: true,
        dataReports: {},
        analyticalReports: {},
        year: new Date().getFullYear() - 1,
        file_url: ''
      }
    },
    created() {
      if (this.$route.params.id) {
        this.analyticalReportsRequest();
      }
    },
    watch: {
      $route(to, from) {
        this.analyticalReportsRequest(this.year);
      }
    },
    computed: {
      reportingPeriods () {
        return this.$store.getters['reportingPeriods/getReportingPeriods'];
      }
    },
    methods: {
      analyticalReportsRequest(year) {
        if (this.$route.params.id !== "index") {
          this.showTemplate = false;
          if (year) {
            this.year = year;
          }
          let params = {year: this.year, id: this.$route.params.id};
          this.$store.dispatch('companyProducts/getAnalyticalReports', params).then(() => {
            setTimeout(() => {
              this.showTemplate = true;
            }, this.timerDelay);
            this.isError = false;
            this.dataReports = this.$store.getters['companyProducts/getAnalyticalReports'];
            this.analyticalReports = this.dataReports.analytical_reports;
            this.analyticalReports[0].active = true;
            this.currentFileUrl(0);
          }).catch(() => {
            this.isError = this.$store.getters['companyProducts/getError'];
          });
        }
      },
      currentFileUrl(index) {
        return new Promise(resolve => {
          if (this.analyticalReports.length > 0) {
            this.file_url = this.analyticalReports[index].url;
          }
          resolve()
        })
      },
      activeYear (year) {
        return this.year === year;
      },
      changeByYear (year) {
        this.year = year;
        this.analyticalReportsRequest(this.year);
      },
      changeByName (id) {
        this.showTemplate = false;
        for(var i = 0; i < this.analyticalReports.length; i++) {
          if (this.analyticalReports[i].id === id) {
            this.analyticalReports[i].active = true;
            this.currentFileUrl(i).then(() => {
              this.showTemplate = true;
            })
          } else {
            this.analyticalReports[i].active = false;
          }
        }
      }
    }
  }
</script>
