<template lang="pug">
  div
    <!--#prev-btn(@click="prevYear")-->
    template( v-if="title")
      .preview-title.infographics-title(v-html="title")
      .separator
    #reporting_periods( :style="{'margin-top': title ? '10px' : '50px'}")
      template(v-for="reportingPeriod in reportingPeriods")
        .reporting-period-title(
          v-html="reportingPeriod.year"
          @click="changeByYear(reportingPeriod.year)"
          :class="{active: activeYear(reportingPeriod.year)}"
        )
    <!--#next-btn(@click="nextYear")-->
</template>

<script>
  export default {
    name: "ReportingPeriods",
    props: ['title'],
    data() {
      return {
        year: new Date().getFullYear() - 1
      }
    },
    computed: {
      reportingPeriods () {
        return this.$store.getters['reportingPeriods/getReportingPeriods'];
      }
    },
    methods: {
      // prevYear () {
      //   document.getElementById('reporting_periods').scrollLeft -= 46;
      // },
      // nextYear () {
      //   document.getElementById('reporting_periods').scrollLeft += 46;
      // },
      activeYear (year) {
        return this.year === year;
      },
      changeByYear (year) {
        this.year = year;
        this.$emit('dataByYear', year);
        this.$emit('comparativeByYear', year);
      }
    }
  }
</script>
