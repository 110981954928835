<template lang="pug">
  .container-fluid#header
    .container
      .row
        nav.navbar.navbar-expand-lg.navbar-light.custom-navbar
          router-link.navbar-brand( :to="'/'")
            img.logo( :src="logo")
          <!--button.navbar-toggler( aria-controls="navbarToggler" aria-expanded="false" aria-label=("Toggle navigation") data-target="#navbarToggler" data-toggle="collapse" type="button")-->
          <!--span.navbar-toggler-icon-->
          button.navbar-toggler.custom-navbar-toggler( @click="showMenuMobile()")
            img( :src="burgerMenuImage")
          #navbarToggler.collapse.navbar-collapse
            ul.navbar-nav.ml-auto.mt-2.mt-lg-0
              template( v-for="link in menuLinks")
                li.list-dropdown-item( v-if="link.node_type === 'drop_down'" @click="toggleMenu")
                  .nav-link.menu-link( :class="{ 'router-link-active': isMaterialsRoute }")
                    span {{ link.title }}
                  .sub-links( v-show-slide='openMenu.aboutCompany')
                    ul.list-group
                      template( v-for="sub_menu in link.sub_menus")
                        li.list-group-item
                          router-link.nav-link.menu-link.menu-sub-link( :to="sub_menu.link")
                            | {{ sub_menu.title }}
                li.nav-item( v-else @click="closeMenu(link)")
                  router-link.nav-link.menu-link( :to="link.link" :class="{ disabled: !link.active }")
                    | {{ link.title }}
</template>

<script>
import HeaderMenuModal from './HeaderMenuModal'

export default {
  name: 'Header',
  data() {
    return {
      currentRoute: {
        materials: true
      },
      openMenu: {
        aboutCompany: false
      }
    }
  },
  computed: {
    burgerMenuImage() {
      return require('@/burger_icon.png')
    },
    logo() {
      return require('@/logo.svg')
    },
    isMaterialsRoute() {
      return !!(this.$route.path.indexOf('/material/') + 1)
    },
    menuLinks() {
      return this.$store.getters['menus/getMenus']
    }
  },
  methods: {
    closeMenu(link) {
      this.openMenu.aboutCompany = false
    },
    toggleMenu() {
      this.openMenu.aboutCompany = !this.openMenu.aboutCompany
    },
    showMenuMobile() {
      const width = window.outerWidth == 320 ? 280 : 320
      document.body.classList.add('hidden')
      this.$modal.show(
        HeaderMenuModal,
        {
          links: this.menuLinks
        },
        {
          height: '100%',
          pivotX: 1,
          pivotY: 0,
          minWidth: width,
          width: width,
          classes: 'header-menu--modal'
        }
      )
    }
  }
}
</script>

<style lang="scss">
.hidden {
  overflow: hidden;
}

#header {
  padding: 0 11px;
  .custom-navbar {
    margin: 0;
    width: 100%;
    padding: 0;

    .navbar-brand {
      .logo {
        width: 165px;
      }
    }

    .custom-navbar-toggler {
      border: none;
      padding: 0;
    }

    #navbarToggler {
      .navbar-nav {
        .menu-link {
          /*opacity: 0.7;*/
          color: #000000;
          font-size: 16px;
          font-weight: 400;
          height: 60px;
          display: flex;
          align-items: center;

          &.router-link-active {
            background-color: #4cbba7;
            color: white;
          }
        }

        .list-dropdown-item {
          cursor: pointer;

          .sub-links {
            width: max-content;
            position: absolute;
            z-index: 1;
            right: 16px;

            .list-group {
              .list-group-item {
                border: none;

                .menu-sub-link {
                  padding: 8px;
                  opacity: 1;
                  height: 40px;
                  background-color: white;

                  &.router-link-active {
                    background-color: #4cbba7;
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
