<template lang="pug">
  section
    Header( v-if="!is404")
    .main-container
      router-view
    Footer( v-if="!is404")
</template>

<script>
  import Header from './Header'
  import Footer from "./Footer"

  export default {
    name: 'home',
    components: {
      Footer,
      Header
    },
    created() {
      this.getInitData();
    },
    computed: {
      is404() {
        return this.$route.meta.is404
      }
    },
    methods: {
      getInitData() {
        this.$store.dispatch('menus/getMenus')
      }
    }
  }
</script>

<style lang="scss">
  #body {
    .main-container {
      min-height: calc(100vh - 375px);
    }
  }
  @media screen and(max-width: 767px){
     #body {
       
      .main-container {
        min-height: auto;
      }
     }
  }
</style>
