<template lang="pug">
  .container-fluid#body-container
    .row.top-search( :style="{'background-image': 'url(' + backgroundImage + ')'}")
      .container
        .row.form-row
          .col-sm-4.input-block
            .input-group
              input.form-control.border-right-0.border( type='search' placeholder='Прізвище' v-model="query.full_name")
              span.input-group-append
                .input-group-text.bg-white
                  i.fa.fa-search
          .col-sm-4.input-block
            .input-group( @click="showCouncilsModal")
              .form-control.custom-select.selected-councils
                template( v-for="(council, index) in councils" v-if="councils[index].use === true")
                  span.selected-council
                    | {{ council.title }}
              .input-group-append
                i.fas.fa-cog.fa-xs( title="Вибір ради")
          .col-sm-4.input-block
            v-select.form-control.custom-select( :options="totalValues" placeholder="Всі оцінки" v-model="query.total_value" multiple)
    .row
      .container
        .row.d-flex.justify-content-between
          .col-12.col-lg-9
            MobileTable(
              v-if="isMobile()"
              :na="na"
              :markClass="markClass"
              :columnTitles="columnTitles"
              :deputies="deputies"
              :disableMoreDeputiesButton="disableMoreDeputiesButton"
              @show-filters-modal="showFiltersModal"
              @change-sort="changeSort"
              @show-more-deputies="showMoreDeputies"
            )
            DesktopTable(
              v-else
              :na="na"
              :markClass="markClass"
              :columnTitles="columnTitles"
              :deputies="deputies"
              :convocationNumber="convocationNumber"
              :disableMoreDeputiesButton="disableMoreDeputiesButton"
              @show-filters-modal="showFiltersModal"
              @change-sort="changeSort"
              @show-more-deputies="showMoreDeputies"
            )
          .col-12.col-lg-3
            articles
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import FilterModal from './home/FilterModal'
import CouncilsModal from './home/CouncilsModal'
import columnHead from './home/ColumnHeader'
import articles from './home/Articles'
import DesktopTable from './home/desktop/Table'
import MobileTable from './home/mobile/Table'
import {isMobile} from '../utils/mobileDetect'

export default {
  name: 'Home',
  components: {
    'v-select': vSelect,
    'column-head': columnHead,
    articles: articles,
    DesktopTable,
    MobileTable
  },
  data() {
    return {
      timerDelay: 500,
      timer: null,
      depPerPageConstant: 10,
      na: {
        value: -1,
        title: 'ложь *'
      },
      query: {
        full_name: '',
        council: '',
        sorting_by: '',
        total_value: '',
        per_page: 10
      },
      filteredQuery: {},
      columnTitles: [],
      councils: [],
      deputies: [],
      convocationNumber: '',
      totalValues: [],
      prevSortAlias: '',
      markClass: ['mark-1', 'mark-2', 'mark-3', 'mark-4', 'mark-5']
    }
  },
  computed: {
    backgroundImage() {
      return require('@/body_top_background.png')
    },
    disableMoreDeputiesButton() {
      return this.query.per_page > this.deputies.length
    },
    sendRequestAccess() {
      return this.$store.getters['others/getMainPageModalSendRequestAccess']
    }
  },
  watch: {
    query: {
      handler(oldValue, newValue) {
        this.sendQueryWithDelay()
      },
      deep: true
    }
  },
  created() {
    this.getDeputies()
    this.getCriteriaNames()
    this.getCouncils()
    this.getTotalValues()
  },
  methods: {
    isMobile,
    valueFormatter(value) {
      return value === this.na.value ? this.na.title : value
    },
    totalClass(cellValue) {
      return cellValue.column_title_alias === 'TOTAL'
        ? this.markClass[cellValue.value - 1]
        : ''
    },
    isDeputyFullName(cellValue) {
      return cellValue.column_title_alias === 'full_name'
    },
    showMoreDeputies() {
      if (!this.disableMoreDeputiesButton) {
        this.query.per_page += this.depPerPageConstant
      }
    },
    showCouncilsModal() {
      this.$modal.show(
        CouncilsModal,
        {
          councils: this.councils
        },
        {
          height: 'auto',
          width: 820,
          adaptive: true,
          scrollable: true
        },
        {
          'before-close': event => {
            if (this.sendRequestAccess) {
              this.sendQueryWithDelay()
            }
          }
        }
      )
    },
    showFiltersModal() {
      this.$modal.show(
        FilterModal,
        {
          filters: this.columnTitles
        },
        {
          height: 'auto',
          width: 820,
          adaptive: true,
          scrollable: true
        }
      )
    },
    changeSort(method) {
      if (this.prevSortAlias !== method.alias) {
        this.prevSortAlias = method.alias

        for (let ref in this.$refs) {
          if (ref !== method.alias) {
            this.$refs[ref][0].resetCounter()
          }
        }
      }
      Vue.set(
        this.query,
        'sorting_by',
        !!method.sortType
          ? method
          : { sortType: 'asc', alias: 'full_name', id: -1 }
      )
    },
    sendQueryWithDelay() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.dataFormatter()
      }, this.timerDelay)
    },
    dataFormatter() {
      Object.assign(this.filteredQuery, this.query)

      let councils_ids = []
      $.each(this.councils, function(key, value) {
        if (value.use === true) {
          councils_ids.push(parseInt(value.id))
        }
      })

      this.filteredQuery.council = councils_ids

      for (let ref in this.filteredQuery) {
        if (this.filteredQuery[ref] === null) {
          this.filteredQuery[ref] = ''
        }
      }

      this.getDeputies()
    },
    async getDeputies() {
      await this.$store.dispatch('deputies/getDeputies', this.filteredQuery)
      this.deputies = this.$store.getters['deputies/getDeputies']
      this.convocationNumber = this.$store.getters[
        'deputies/getConvocationNumber'
      ]
    },
    async getCriteriaNames() {
      await this.$store.dispatch('criteriaNames/getCriteriaNames')
      this.columnTitles = this.$store.getters['criteriaNames/getCriteriaNames']
    },
    async getCouncils() {
      await this.$store.dispatch('councils/getCouncilsInfo')
      this.councils = this.$store.getters['councils/getCouncilsInfo']
    },
    async getTotalValues() {
      await this.$store.dispatch('criteriaValues/getUniqTotalValues')
      this.totalValues = this.$store.getters[
        'criteriaValues/getUniqTotalValues'
      ]
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 767px) {
  div#body-container {
    .top-search {
      .form-row {
        margin-top: 0;
      }
    }
  }
}

@media (max-width: 991px) {
  div#body-container {
    padding-bottom: 10px;

    .top-search {
      .form-row {
        .input-block {
          margin: 15px 0;
        }
      }
    }
  }
}

#body-container {
  background-color: #f8f8f8;
  padding-bottom: 20px;

  i.fa-cog {
    color: #4d4d4d;
    background-color: #f8f8f8;
    padding: 12px;
    cursor: pointer;
    font-size: 12px;
  }

  #modals-container {
    .v--modal-overlay {
      width: calc(100% - 5px) !important;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .top-search {
    height: 215px;

    .form-row {
      margin-top: 52px;

      input.form-control {
        padding: 8px;
        font-size: 14px;

        &:focus {
          outline: 0 !important;
          border-color: initial;
          box-shadow: none;
        }
      }

      .input-group {
        .input-group-append {
          i.fa-cog {
            padding: 11px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
      }

      .custom-select {
        padding: 0;
        border: none;
        background-color: #fff;
        background-image: none;
        font-size: 14px;

        &.selected-councils {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 5px;
          cursor: pointer;

          &:after {
            content: '';
            position: absolute;
            right: 16px;
            top: 0;
            width: 50px;
            height: 100%;
            background: rgba(237, 237, 237, 0);
            background: -moz-linear-gradient(
              left,
              rgba(237, 237, 237, 0) 0%,
              rgba(246, 246, 246, 0.06) 5%,
              rgba(255, 255, 255, 0.18) 16%,
              rgba(255, 255, 255, 1) 87%
            );
            background: -webkit-gradient(
              left top,
              right top,
              color-stop(0%, rgba(237, 237, 237, 0)),
              color-stop(5%, rgba(246, 246, 246, 0.06)),
              color-stop(16%, rgba(255, 255, 255, 0.18)),
              color-stop(87%, rgba(255, 255, 255, 1))
            );
            background: -webkit-linear-gradient(
              left,
              rgba(237, 237, 237, 0) 0%,
              rgba(246, 246, 246, 0.06) 5%,
              rgba(255, 255, 255, 0.18) 16%,
              rgba(255, 255, 255, 1) 87%
            );
            background: -o-linear-gradient(
              left,
              rgba(237, 237, 237, 0) 0%,
              rgba(246, 246, 246, 0.06) 5%,
              rgba(255, 255, 255, 0.18) 16%,
              rgba(255, 255, 255, 1) 87%
            );
            background: -ms-linear-gradient(
              left,
              rgba(237, 237, 237, 0) 0%,
              rgba(246, 246, 246, 0.06) 5%,
              rgba(255, 255, 255, 0.18) 16%,
              rgba(255, 255, 255, 1) 87%
            );
            background: linear-gradient(
              to right,
              rgba(237, 237, 237, 0) 0%,
              rgba(246, 246, 246, 0.06) 5%,
              rgba(255, 255, 255, 0.18) 16%,
              rgba(255, 255, 255, 1) 87%
            );
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#ffffff', GradientType=1);
          }

          .selected-council {
            height: 100%;
            max-height: 35px;
            line-height: 35px;
            margin: 0;
            padding: 8px;
            border: none;
            background-color: transparent;
          }
        }

        .dropdown-toggle {
          border: none;
          padding: 0;

          &:after {
            display: none;
          }

          .vs__actions {
            .clear {
              font-size: 18px;
            }

            .open-indicator {
              padding-bottom: 1px;

              &:before {
                border-width: 2px 2px 0 0;
                height: 6px;
                width: 6px;
              }
            }
          }

          .vs__selected-options {
            padding: 0;

            input[type='search'] {
              margin: 0;
              border: none;
            }

            .selected-tag {
              height: 100%;
              max-height: 35px;
              margin: 0;
              padding: 8px;
              border: none;
              background-color: transparent;
            }
          }
        }

        .dropdown-menu {
          top: 30px;
          border: none;

          li {
            * {
              font-size: 14px;
            }
          }

          a {
            &:hover {
              background-color: grey;
            }
          }

          .highlight {
            a {
              background-color: grey;
            }
          }
        }
      }
    }
  }

  .more-deputies {
    display: flex;
    justify-content: center;
    padding: 17px 0;

    .show-more-deputies-button {
      outline: 0 !important;
      border-color: initial;
      box-shadow: none;
      width: 180px;
      height: 31px;
      border-radius: 4px;
      background-color: #05a081;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 0.3s linear;
      color: #ffffff;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;

      ::-moz-focus-outer,
      ::-moz-focus-inner {
        border: 0;
        padding: 0;
      }

      &:hover {
        opacity: 1;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
