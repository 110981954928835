<template lang="pug">
  div
    div#preview-file-container
      .search-block-mobile
        .input-group
          input.form-control(type='text' title="Пошук по таблиці" placeholder='Пошук по таблиці' v-model="query")
          .input-group-prepend( title="Пошук по таблиці")
            .input-group-text
              i.fas.fa-search
      .search-block
        .input-group
          .input-group-prepend( title="Пошук по таблиці")
            .input-group-text
              i.fas.fa-search
          input.form-control(type='text' title="Пошук по таблиці" placeholder='Пошук по таблиці' v-model="query")
          .input-group-append
            .btn.btn-light.btn-outline-secondary.clr-query-btn( title="Очистити" @click="clrQuery()")
              i.fas.fa-times
      .table-responsive.table-preview( :class="{'with-years': classWithYears}")
        table.table.table-bordered
          thead
            tr
              template( v-for="columnTitle in file.header")
                columnHeader( :object="columnTitle" :ref="'column-title-' + columnTitle[1]" v-on:change-sort="changeSort")
          tbody
            template( v-for="row in file.table")
              tr
                template( v-for="cell in row" v-if="cell.show")
                  td.cell-values
                    span
                      | {{ cell.value }}
</template>

<script>
import columnHeader from './PreviewHeader'

export default {
  name: 'TablePreview',
  props: ['file', 'classWithYears'],
  components: {
    columnHeader
  },
  data() {
    return {
      timerDelay: 1000,
      timer: null,
      preview: {},
      prevSortAlias: '',
      sortingBy: { alias: '', sortType: 'ASC' },
      query: '',
      filers: ''
    }
  },
  watch: {
    file() {
      this.preview = this.file
    },
    query() {
      this.findWithDelay()
    }
  },
  methods: {
    findWithDelay() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.tableSearch()
      }, this.timerDelay)
    },
    tableSearch() {
      this.file.table.forEach((row, index) => {
        let isRowWithResult = false

        row.forEach((cell, index) => {
          if (
            cell.value.toLowerCase().indexOf(this.query.toLowerCase()) !== -1
          ) {
            isRowWithResult = true
          }
        })

        if (isRowWithResult) {
          row.forEach(cell => {
            cell.show = true
          })
        } else {
          row.forEach(cell => {
            cell.show = false
          })
        }
      })
    },
    changeSort(sortMethod) {
      if (this.prevSortAlias !== sortMethod.id) {
        this.prevSortAlias = sortMethod.id

        for (let ref in this.$refs) {
          if (ref !== 'column-title-' + sortMethod.id) {
            this.$refs[ref][0].resetCounter()
          }
        }
      }

      this.file.table.sort((a, b) =>
        a[sortMethod.id - 1].value.localeCompare(
          b[sortMethod.id - 1].value,
          'ua',
          { ignorePunctuation: true }
        )
      )

      if (sortMethod.sortType === 'asc') {
        this.file.table.reverse()
      }
    },
    clrQuery() {
      this.query = ''
    }
  }
}
</script>

<style scoped lang="scss">
.search-block {
  display: block;
  margin: 5px 0;
  width: 50%;

  .clr-query-btn {
    border-color: #ced4da;
    cursor: pointer;
  }
}

.table-preview {
  font-size: 14px;
  height: calc(86vh - 210px);

  &::-webkit-scrollbar-track {
    background-color: #b8b8b8;
  }

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    width: 271px;
    background-color: #626262;
  }

  &.with-years {
    height: calc(86vh - 210px);
  }

  table {
    th,
    td {
      width: auto;
    }
  }

  .cell-values {
    padding: 1px 4px 2px 4px;
  }
}

.search-block-mobile {
  margin: 20px 0;
  width: 50%;
  display: none;

  .input-group {
    padding: 20px 0;
    border-bottom: 1px solid rgba($color: #080808, $alpha: 0.2);

    input {
      padding: 0;
      padding-right: 20px;
      border: none;
      border-radius: 0;
      background: transparent;
      box-shadow: none;
      overflow: hidden;
      font-size: 16px;
      text-align: left;
      vertical-align: top;
    }

    .input-group-prepend {
      width: 44px;
      height: 44px;
      margin: auto;

      .input-group-text {
        border: none;
      }
    }
  }

  .clr-query-btn {
    border-color: #ced4da;
    cursor: pointer;
  }
}

@media screen and(max-width: 767px) {
  .search-block {
    width: 100%;
    display: none;
  }

  .search-block-mobile {
    width: 100%;
    display: block;
  }

  .table-preview {
    max-height: 612px;

    .cell-values {
      span {
        white-space: nowrap;
      }
    }
  }
}
</style>
