import axios from "axios"

const actions = {
  getCriteriaNames(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/criteria_names.json').then(response => {
        let criteriaNames = response.data.column_titles;
        context.commit('SET_CRITERIA_NAMES', criteriaNames);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  }
};

const mutations = {
  SET_CRITERIA_NAMES(context, payload) {
    context.criteriaNames = payload;
  }
};

const state = {
  criteriaNames: []
};

const getters = {
  getCriteriaNames(state) {
    return state.criteriaNames
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}
