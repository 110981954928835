<template lang="pug">
  .company-product.infographics
    .comparative-infographics
      button.btn.comparative-button(@click="comparativeInfographics(new Date().getFullYear() - 1)") Порівняльна інфографіка
    reporting-periods( :title="infographics.title" :error="isError" :reporting_periods="infographics.reporting_periods" v-on:dataByYear="infographicsRequest")
    template(v-if="!isError")
      #wrapper_images
        Slider(animation="fade" :autoplay="false")
          SliderItem(v-for="(image, index) in list" @click="changeIndex(1)" :key="index")
            img(:src="image")
    template(v-else)
      .preview-title
        | {{ isError }}
</template>

<script>
  import { Slider, SliderItem } from "vue-easy-slider";
  import reportingPeriods from '../company_products/ReportingPeriods.vue'

  export default {
    name: "Infographics",
    components: {
      Slider,
      SliderItem,
      reportingPeriods
    },
    data() {
      return {
        list: [],
        isError: false,
        infographics: {},
        year: new Date().getFullYear()
      }
    },
    created() {
      if (this.$route.params.id) {
        this.infographicsRequest();
      }
    },
    watch: {
      $route(to, from) {
        this.infographicsRequest(this.year);
      }
    },
    methods: {
      changeIndex(index) {
        this.sliderValue = index;
      },
      infographicsRequest(year) {
        this.list = [];
        if (this.$route.params.id !== "index") {
          if (year) {
            this.year = year;
          } else {
            this.year = this.year - 1;
          }
          let params = { year: this.year, id: this.$route.params.id };
          this.$store.dispatch('companyProducts/getInfographics', params).then(() => {
            this.isError = false;
            this.infographics = this.$store.getters['companyProducts/getInfographics'];
            this.list = this.infographics.images;
          }).catch(() => {
            this.isError = this.$store.getters['companyProducts/getError'];
          });
        }
      },
      comparativeInfographics(year) {
        this.list = [];
        if (year) {
          this.year = year;
        }
        let params = { year: this.year, id: 0 };
        this.$store.dispatch('companyProducts/getComparativeInfographics', params).then(() => {
          this.isError = false;
          this.$router.push({ path: '/company_products/infographics/0' });
          this.infographics = this.$store.getters['companyProducts/getComparativeInfographics'];
          this.list = this.infographics.images;
        }).catch(() => {
          this.isError = this.$store.getters['companyProducts/getError'];
        });
      }
    }
  }
</script>
