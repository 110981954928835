<template lang="pug">
  #material-container
    .material
      .row
        .col-md-3.left-block
          .image
            img(:src="material.image")
        .col-md-9.right-block
          .title-block
            .title(v-html="material.title")
            .publication(v-html="material.publication_time")
          .facebook
            img(:src="facebook")
          .description(v-html="material.description")
          .row
            .col-12.col-sm-6( v-for="file in material.files")
              a.file-preview-link( :href="file.url" :title="file.name" target="_blank")
                img.file-image(:src="paperclip")
                .file-title
                  span {{ file.name }}
</template>

<script>
  export default {
    name: "AboutDeputyMaterial",
    data() {
      return {
        material: false
      }
    },
    created() {
      this.getMaterial();
    },
    watch: {
      $route(to, from) {
        this.getMaterial();
      }
    },
    computed: {
      facebook() {
        return require('@/facebook.png')
      },
      paperclip() {
        return require('@/paperclip')
      }
    },
    mounted () {
      this.imagesFix()
    },
    methods: {
      getMaterial() {
        this.$store.dispatch('materials/getMaterial', this.$route.params.alias).then(() => {
          this.material = this.$store.getters['materials/getMaterial']
        })
      },
      imagesFix () {
        let parentEl = document.getElementsByClassName('description')[0]
        let parentWidth = parentEl.offsetWidth
        let images = parentEl.getElementsByTagName('img')
        for(let i = 0; i < images.length; i++) {
          if (parentWidth < parseInt(images[i].style.width)) {
            images[i].style.width = "100%"
            images[i].style.height = "auto"
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  @media (max-width: 575px) {
    #material-container {
      .material {
        .right-block {
          .title-block, .description, .file-preview-link {
            padding-left: 15px;
          }
        }
      }
    }
  }

  #material-container {
    /*padding: 15px 0;*/
    background-color: #F8F8F8;

    .breadcrumb-custom {
      ul {
        li {
          color: #888888;
          font-size: 12px;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }

    .material {
      margin-top: -65px;
      border-radius: 4px;
      margin-bottom: 20px;
      background-color: #ffffff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

      .left-block {
        .image {
          img {
            width: 100%;
            height: auto;
            border-radius: 4px 0 0 0;
          }
        }

        .creators {
          margin: 19px;
          color: #888888;
          font-size: 14px;
          font-family: 'Open Sans', sans-serif;
        }
      }

      .right-block {
        padding: 15px;

        .title-block {
          display: inline-block;

          .title {
            color: #222222;
            font-size: 30px;
            font-weight: 700;

          }

          .publication {
            color: #888888;
            font-size: 12px;

          }
        }

        .facebook {
          float: right;
          margin-top: 10px;
          padding: 4px 12px;
          margin-right: 15px;
          display: inline-block;
          background-color: #f8f8f8;
        }

        .description {
          color: #555555;
          font-size: 14px;
          margin-top: 15px;
          line-height: 24px;
          padding-right: 42px;
          font-family: 'Open Sans', sans-serif;
        }
      }

      .file-preview-link {
        margin-bottom: 15px;
        display: inline-flex;
        align-items: center;

        &:hover {
          color: #05a081;

          .file-title {
            color: inherit;
          }
        }

        .file-image {
          margin-right: 10px;
          display: inline-block;
          width: 43px;
          height: 49px;
        }

        .file-title {
          word-break: break-all;
          color: #555555;
          font-size: 14px;
          display: inline-block;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }
  }
</style>