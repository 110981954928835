import Vue from 'vue'
import Vuex from 'vuex'

import reportingPeriods from './modules/reportingPeriods'
import councils from './modules/councils'
import articles from './modules/articles'
import deputies from './modules/deputies'
import criteriaValues from './modules/criteriaValues'
import deputiesInterests from './modules/deputiesInterests'
import criteriaNames from './modules/criteriaNames'
import materials from './modules/materials'
import others from './modules/others'
import companyProducts from './modules/companyProducts'
import menus from './modules/menus'
import breadcrumbs from './modules/breadcrumbs'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    reportingPeriods,
    councils,
    articles,
    deputies,
    criteriaValues,
    deputiesInterests,
    criteriaNames,
    materials,
    others,
    companyProducts,
    menus,
    breadcrumbs
  }
});

export default store
