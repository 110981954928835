<template lang="pug">
  .container-fluid#material-container
    .container
      Breadcrumbs( :current="materialTitle")
      .material
        .row
          .col-md-3.left-block
            .image
              img(:src="material.image")
          .col-md-9.right-block
            .title-block
              .title(v-html="materialTitle")
              .publication(v-html="material.publication_time")
            //.facebook
              social-sharing(inline-template)
                div
                  network(network="facebook")
                    i.fab.fa-facebook-f
            .description#description(v-html="material.description")
            .row
              .col-12.col-sm-6( v-for="file in material.files")
                a.file-preview-link( :href="file.url" :title="file.name" target="_blank")
                  img.file-image(:src="paperclip")
                  .file-title
                    span {{ file.name }}
</template>

<script>
  import Breadcrumbs from '../../layout/Breadcrumbs'
  import imagesFix from '../../utils/imagesFix'

  export default {
    name: "Show",
    components: {
      Breadcrumbs
    },
    data() {
      return {
        material: false,
        descriptionObserver: null
      }
    },
    created() {
      this.getMaterial();
    },
    watch: {
      $route(to, from) {
        this.getMaterial();
      }
    },
    // metaInfo() {
    //   return {
    //     meta: [
    //       {property: 'og:title', content: this.materialTitle},
    //       {property: 'og:description', content: this.material.description},
    //       {property: 'og:image', content: window.location.origin + this.material.image},
    //       {property: 'og:type', content: 'Article'},
    //       {property: 'og:url', content: window.location.href}
    //     ]
    //   }
    // },
    computed: {
      paperclip() {
        return require('@/paperclip')
      },
      materialTitle() {
        return this.material.title
      }
    },
    mounted () {
      this.imagesFix()
    },
    beforeDestroy() {
      this.descriptionObserver.disconnect()
    },
    methods: {
      getMaterial() {
        this.$store.dispatch('materials/getMaterial', this.$route.params.alias).then(() => {
          this.material = this.$store.getters['materials/getMaterial']
        })
      },
      imagesFix () {
        const target = document.getElementById('description')
        const config = {
          attributes: true,
          childList: true,
          subtree: true
        }

        this.descriptionObserver = new MutationObserver(imagesFix)
        this.descriptionObserver.observe(target, config);
      }
    }
  }
</script>

<style lang="scss" scoped>
  #material-container {
    padding: 15px 0;
    background-color: #F8F8F8;

    .breadcrumb-custom {
      ul {
        li {
          color: #888888;
          font-size: 12px;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }

    .material {
      margin-top: 30px;
      border-radius: 4px;
      margin-bottom: 20px;
      background-color: #ffffff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

      .left-block {
        .image {
          img {
            width: 100%;
            height: auto;
            border-radius: 4px 0 0 0;
          }
        }

        .creators {
          margin: 19px;
          color: #888888;
          font-size: 14px;
          font-family: 'Open Sans', sans-serif;
        }
      }

      .right-block {
        padding: 15px;

        .title-block {
          display: inline-block;

          .title {
            color: #222222;
            font-size: 30px;
            font-weight: 700;

          }

          .publication {
            color: #888888;
            font-size: 12px;

          }
        }

        .facebook {
          float: right;
          margin-top: 10px;
          padding: 4px 12px;
          margin-right: 15px;
          display: inline-block;
          background-color: #f8f8f8;
        }

        .description {
          color: #555555;
          font-size: 14px;
          margin-top: 15px;
          line-height: 24px;
          padding-right: 42px;
          font-family: 'Open Sans', sans-serif;
        }
      }

      .file-preview-link {
        margin-bottom: 15px;
        display: inline-flex;
        align-items: center;

        &:hover {
          color: #05a081;

          .file-title {
            color: inherit;
          }
        }

        .file-image {
          margin-right: 10px;
          display: inline-block;
          width: 43px;
          height: 49px;
        }

        .file-title {
          word-break: break-all;
          color: #555555;
          font-size: 14px;
          display: inline-block;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }
  }

  @media (max-width: 575px) {
    #material-container {
      .material {
        .right-block {
          .title-block, .description, .file-preview-link {
            padding-left: 15px;
          }

          .description {
            padding-right: 15px;
          }
        }
      }
    }
  }
</style>