<template lang="pug">
  .container.articles-container( v-if="articles")
    .row
      .articles-breadcrumbs.col-12.col-md-12
        Breadcrumbs
      .col-12.col-md-8.input-block
        .block-search
          .input-group.desktop
            input.form-control( type='search' name="search" placeholder="Введіть ключові слова для пошуку" v-model="query")
            span.input-group-append
              .input-group-text.bg-white
                i.fa.fa-search
          .input-group.mobile
            input.form-control( type='search' name="search" placeholder="Введіть ключові слова для пошуку" v-model="querySearch")
            span.input-group-append(@click="AddListSearch(querySearch)")
              .input-group-text.bg-white
                i.fa.fa-search
          .search(v-show="query.length>0")
            .search-data
              |{{ query }}
            span(@click="deleteSearch(search_articles)")
              |&times
      .col-md-4.input-block
        v-select.form-control.custom-select( :options="sortingByOptions" label="title" placeholder="Спочатку нові" v-model="sortingBy")
    .row.articles-base
      p.all-articles__no-articles-message(v-if="!articles.length") За запитом "{{query}}" нічого не знайдено
      template(v-else v-for="(article, idx) in articles")
        .all-articles.col-12.col-md-6.col-lg-4
          .single-article
            header
              router-link(:to="'/article/' + article.id" class="title")
                | {{ article.title }}
              .date
                | {{ article.createdAt }}
            body
              router-link(:to="'/article/' + article.id" tag="div" class="article-img")
                img( :src="article.image")
              .article-description( v-html="article.shortDescription.slice(0, 190)")
            footer
              .tags
                .tags__fadeout
                template(v-for="(tag, index) in article.tags")
                  .tag(@click="articlesByTag(tag.title)")
                    | {{ tag.title }}
                    span.coma(v-if="index !== article.tags.length - 1")
                      | ,
    .row( v-if="showPagination")
      .col-12.col-md-12.d-flex.justify-content-center
        nav
          ul.pagination
            li.page-item( :class="{ disabled: pagination.first_page }")
              span( @click='changePage(1)')
                | Перша
            li.page-item( v-for='page in numberOfAvailablePages' :class="{ active: isCurrentPage(page) }")
              span( @click='changePage(page)')
                | {{ page }}
            li.page-item( :class='{ disabled: pagination.last_page }')
              span( @click='changePage(pagination.total_pages)')
                | Остання
</template>

<script>
import Breadcrumbs from '../../layout/Breadcrumbs'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    Breadcrumbs,
    'v-select': vSelect
  },
  data() {
    return {
      timerDelay: 1000,
      timer: null,
      title: 'title',
      articles: false,
      sortedArticles: [],
      currentPage: 1,
      pagination: {},
      query: '',
      querySearch: '',
      tag: '',
      sortingBy: {
        title: 'Спочатку нові',
        alias: 'created_at',
        sortType: 'ASC'
      },
      sortingByOptions: [
        { title: 'Спочатку нові', alias: 'createdAt', sortType: 'ASC' },
        { title: 'Спочатку старі', alias: 'createdAt', sortType: 'DESC' },
        { title: 'Від А до Я', alias: 'title', sortType: 'DESC' },
        { title: 'Від Я до А', alias: 'title', sortType: 'ASC' }
      ],
      search_data: []
    }
  },
  created() {
    if (this.$route.query.tag !== '') {
      this.articlesByTag(this.$route.query.tag)
    } else {
      this.getArticles()
    }
  },
  watch: {
    sortingBy: {
      handler(oldValue, newValue) {
        this.changeSort()
      },
      deep: true
    },
    currentPage() {
      this.getArticles()
    },
    query() {
      this.sendQueryWithDelay()
    }
  },
  computed: {
    numberOfAvailablePages: function() {
      let right =
        this.pagination.total_pages - this.pagination.current_page > 2
          ? 2
          : this.pagination.total_pages - this.pagination.current_page
      let left =
        this.pagination.current_page - 1 > 2
          ? 2
          : this.pagination.current_page - 1
      let arr = []

      arr.unshift(this.pagination.current_page)

      for (let i = 1; i < left + 1; i++) {
        arr.unshift(this.pagination.current_page - i)
      }

      for (let j = 1; j < right + 1; j++) {
        arr.push(this.pagination.current_page + j)
      }

      return arr
    },
    showPagination() {
      if (this.pagination) {
        return this.pagination.total_pages > 1
      }
    }
  },
  methods: {
    sendQueryWithDelay() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.getArticles()
      }, this.timerDelay)
    },
    changeSort() {
      this.articles.sort((a, b) =>
        a[this.sortingBy.alias].localeCompare(b[this.sortingBy.alias], 'ua', {
          ignorePunctuation: true
        })
      )
      if (this.sortingBy.sortType === 'ASC') {
        this.articles.reverse()
      }
    },
    changePage(index) {
      this.currentPage = index
    },
    isCurrentPage: function(index) {
      return this.pagination.current_page === index
    },
    getArticles() {
      let params = {
        pageIndex: this.currentPage,
        query: this.query,
        tag: this.tag
      }

      this.$store.dispatch('articles/getAllArticles', params).then(() => {
        this.articles = this.$store.getters['articles/getAllArticles']
        this.pagination = this.$store.getters['articles/getPagination']
      })
    },
    articlesByTag(tag) {
      this.tag = tag
      this.getArticles()
    },
    AddListSearch(value) {
      this.query = value
      this.querySearch = ''
    },
    deleteSearch(value) {
      this.query = ''
    }
  }
}
</script>

<style lang="scss">
.articles-container {
  .breadcrumb-custom {
    ul {
      li {
        color: #888888;
      }
    }
  }

  .input-block {
    margin-bottom: 50px;

    .input-group {
      box-shadow: 0 2px 3px rgba(23, 2, 37, 0.15);
      border-radius: 4px;

      .desktop {
        display: none;
      }

      .form-control {
        border: none;
      }

      .input-group-append {
        .input-group-text {
          border: none;
        }
      }
    }

    .input-group.mobile {
      display: none;
    }

    .search {
      display: none;
    }

    input.form-control {
      padding: 8px;
      font-size: 14px;

      &:focus {
        outline: 0 !important;
        border-color: initial;
        box-shadow: none;
      }
    }

    .custom-select {
      padding: 0;
      border: none;
      background-color: #fff;
      background-image: none;
      font-size: 14px;
      box-shadow: 0 2px 3px rgba(23, 2, 37, 0.15);

      .dropdown-toggle {
        border: none;
        padding: 0;

        &:after {
          display: none;
        }

        .vs__actions {
          .clear {
            font-size: 18px;
          }

          .open-indicator {
            padding-bottom: 1px;

            &:before {
              border-width: 2px 2px 0 0;
              height: 6px;
              width: 6px;
            }
          }
        }

        .vs__selected-options {
          padding: 0;

          input[type='search'] {
            margin: 0;
            border: none;
            padding-left: 16px;
          }

          .selected-tag {
            height: 100%;
            margin: 0;
            padding-left: 15px;
          }
        }
      }

      .dropdown-menu {
        top: 30px;
        border: none;

        li {
          * {
            font-size: 14px;
          }
        }

        a {
          &:hover {
            background-color: grey;
          }
        }

        .highlight {
          a {
            background-color: grey;
          }
        }
      }
    }
  }

  .single-article {
    border-radius: 4px;
    background-color: white;
    padding: 16px 20px 15px 20px;
    margin-bottom: 20px;
    transition: all 0.3s linear;

    &:hover {
      -webkit-box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.2);
    }

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        color: #222222;
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
      }

      .date {
        color: #888888;
        font-size: 12px;
        font-weight: bold;
      }
    }

    body {
      margin: 15px 0;

      .article-img {
        text-align: center;
        margin: 0px -20px;
        cursor: pointer;

        img {
          width: 100%;
        }
      }

      .article-description {
        margin-top: 20px;
        color: #555555;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }

    footer {
      .tags {
        padding-left: 15px;
        max-height: 100px;
        overflow: hidden;
        position: relative;

        .tags__fadeout {
          width: 100%;
          height: 100%;
          position: absolute;
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1)
          );
        }

        .tag {
          color: #888888;
          font-size: 14px;
          margin-right: 5px;
          display: inline-block;
          font-family: 'Open Sans', sans-serif;

          &:hover {
            color: #05a081;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .pagination {
    .page-item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      cursor: pointer;
      color: #888888;

      &:first-child {
        margin-right: 20px;
      }

      &:last-child {
        margin-left: 20px;
      }

      &:first-child,
      &:last-child {
        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }

      &:not(:first-child):not(:last-child) {
        width: 31px;
        height: 31px;

        &.active {
          background-color: #05a081;
          color: white;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .articles-container {
    .articles-breadcrumbs {
      padding: 0;
      margin-bottom: 10px;
    }

    .input-block {
      padding: 0;
      margin-bottom: 10px;
      box-shadow: 0 2px 3px rgba(23, 2, 37, 0.15);

      .block-search {
        background-color: #ffffff;
        border-radius: 5px;
        padding: 0 10px;
        max-width: 100%;

        .input-group {
          box-shadow: none;
          border-bottom: 1px solid #dfdfdf;

          .form-control {
            border: none;
            padding: 0;
            height: auto;
          }

          .input-group-append {
            .input-group-text {
              padding: 24px 6px;
            }
          }
        }

        .input-group.desktop {
          display: none;
        }

        .input-group.mobile {
          display: flex;
        }

        .search {
          white-space: normal;
          display: flex;
          align-items: center;
          border-radius: 4px;
          background-color: #f8f8f8;
          padding: 3px 0 3px 10px;
          margin: 10px 0;
          width: fit-content;

          .search-data {
            font-size: 14px;
            line-height: 19px;
            color: #222222;
          }

          span {
            font-size: 18px;
            padding: 0 9px;
            border-left: 1px solid rgba($color: #000000, $alpha: 0.1);
            margin-left: 9px;
          }
        }
      }

      .custom-select {
        .dropdown-toggle {
          .vs__selected-options {
            .selected-tag {
              padding-left: 10px;
            }
          }

          .vs__actions {
            .clear {
              display: none;
            }

            .open-indicator {
              margin-right: 10px;

              &:before {
                height: 10px;
                width: 10px;
              }
            }
          }
        }

        .dropdown-menu {
          a {
            height: 46px;
            border-bottom: 1px solid #dfdfdf;
            display: flex;
            align-items: center;
            padding-left: 10px;
          }

          .highlight {
            a {
              background-color: #05a081;
            }
          }
        }
      }
    }

    .articles-base {
      margin-top: 30px;

      .all-articles {
        padding: 0;

        .single-article {
          padding: 10px;
          padding-bottom: 20px;
          box-shadow: 0 2px 3px rgba(23, 2, 37, 0.15);

          header {
            .title {
              font-size: 15px;
            }
          }

          body {
            margin: 10px 0 15px 0;

            .article-img {
              margin: 0;
            }
          }

          footer {
            .tags {
              padding: 0;
            }
          }
        }
      }
    }

    .row {
      nav {
        .pagination {
          margin: 10px 0 25px 0;
        }
      }
    }
  }
}
</style>
