<template lang="pug">
  .company-product.schedules(v-if="!isMobile() || !isParamsIdIndex")
    template(v-if="!isParamsIdIndex")
      template(v-if="!isError")
        tablePreview( :file="currentFileData" v-if="currentFileData")
        .download-file
          button.btn.download-button(v-on:click="downloadFile") Завантажити графік
      template(v-else)
        .preview-title
          | {{ isError }}
</template>

<script>
  import XLSX from 'xlsx'
  import tablePreview from '../../layout/table_preview/TablePreview'
  import {isMobile} from '../../utils/mobileDetect'
  export default {
    name: "Schedules",
    components: {
      tablePreview
    },
    data() {
      return {
        file: '',
        isError: false,
        schedule: {}
      }
    },
    created() {
      if (this.$route.params.id) {
        this.scheduleRequest();
      }
    },
    watch: {
      $route(to, from) {
        this.scheduleRequest(this.year);
      }
    },
    computed: {
      currentFileUrl () {
        return this.schedule.file_url;
      },
      currentFileData () {
        return this.schedule.table;
      },
      isParamsIdIndex () {
        return this.$route.params.id === "index"
      }
    },
    methods: {
      isMobile,
      scheduleRequest(year) {
        if (!this.isParamsIdIndex) {
          this.$store.dispatch('companyProducts/getSchedule', this.$route.params.id).then(() => {
            this.isError = false;
            this.schedule = this.$store.getters['companyProducts/getSchedule'];
            this.getFile();
          }).catch(() => {
            this.isError = this.$store.getters['companyProducts/getError'];
          });
        }
      },
      downloadFile() {
        let link = document.createElement('a');
        link.href = this.currentFileUrl;
        link.setAttribute('download', this.schedule.file_name);
        document.body.appendChild(link);
        link.click();
      },
      getFile() {
        let that = this;
        fetch(this.currentFileUrl).then(function (response) {
          return response.arrayBuffer();
        }).then(function (array_buffer) {
          let data = new Uint8Array(array_buffer);
          let wb = XLSX.read(data, {type: 'array'});
          that.file = XLSX.write(wb, {type: 'string', bookType: 'html'});
        });
      }
    }
  }
</script>
