import axios from "axios"

const actions = {
  getArticle(context, payload) {
    let id = payload;
    return new Promise((resolve, reject) => {
      axios.get('/api/article/' + id + '.json').then(response => {
        let article = response.data.article;
        context.commit('SET_ARTICLE', article);
        resolve();
      }).catch(errors => {
        reject(errors);
      })
    })
  },
  getArticlesList(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/articles_list.json').then(response => {
        let ArticlesList = response.data;
        context.commit('SET_ARTICLES_LIST', ArticlesList);
        resolve();
      }).catch(errors => {
        reject(errors);
      })
    })
  },
  getMainPageArticles(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/main_page_articles.json').then(response => {
        let mainPageArticles = response.data;
        context.commit('SET_MAIN_PAGE_ARTICLES', mainPageArticles);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  },
  getDeputyArticles(context, payload) {
    let id = payload;
    return new Promise((resolve, reject) => {
      axios.get('/api/deputy_articles.json', {
        params: {
          deputy_id: id
        }
      }).then(response => {
        let deputyArticles = response.data;
        context.commit('SET_DEPUTY_ARTICLES', deputyArticles);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  },
  getAllArticles(context, payload) {
    let pageIndex = payload.pageIndex;
    let query = payload.query;
    let tag = payload.tag;
    return new Promise((resolve, reject) => {
      axios.get('/api/articles.json', {
        params: {
          page_index: pageIndex,
          query: query,
          tag: tag
        }
      }).then(response => {
        let allArticles = response.data.articles;
        let pagination = response.data.pagination;
        context.commit('SET_ALL_ARTICLES', allArticles);
        context.commit('SET_PAGINATION', pagination);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  }
};

const mutations = {
  SET_ARTICLE(context, payload) {
    context.article = payload;
  },
  SET_ARTICLES_LIST(context, payload) {
    context.articlesList = payload;
  },
  SET_MAIN_PAGE_ARTICLES(context, payload) {
    context.mainPageArticles = payload;
  },
  SET_DEPUTY_ARTICLES(context, payload) {
    context.deputyArticles = payload;
  },
  SET_ALL_ARTICLES(context, payload) {
    context.allArticles = payload;
  },
  SET_PAGINATION(context, payload) {
    context.pagination = payload;
  }
};

const state = {
  article: [],
  articlesList: [],
  mainPageArticles: [],
  deputyArticles: [],
  allArticles: [],
  pagination: {}
};

const getters = {
  getArticle(state) {
    return state.article
  },
  getArticlesList(state) {
    return state.articlesList
  },
  getMainPageArticles(state) {
    return state.mainPageArticles
  },
  getDeputyArticles(state) {
    return state.deputyArticles
  },
  getAllArticles(state) {
    return state.allArticles
  },
  getPagination(state) {
    return state.pagination
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}
