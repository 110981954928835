<template lang="pug">
  li.list-group-item( v-if="link.node_type === 'drop_down'" @click="toggleMenu")
    .nav-link.menu-link( :class="{ 'router-link-active': isMaterialsRoute }")
      span {{ link.title }}
      i.fas.fa-chevron-down.ml-auto( :class="{ 'rotate-180': open }")
    .sub-links( v-show-slide='open')
      ul.list-group
        template( v-for="sub_menu in link.sub_menus")
          li.list-group-item( @click="closeModal(false)")
            router-link.nav-link.menu-link.menu-sub-link( :to="sub_menu.link")
              | {{ sub_menu.title }}
  li.list-group-item( v-else @click="closeModal(!link.active)")
    router-link.nav-link.menu-link( :to="'/'+link.alias" :class="{ disabled: !link.active }")
      | {{ link.title }}
</template>

<script>
  export default {
    name: "SingleMenuLink",
    props: ['link'],
    data() {
      return {
        open: false
      }
    },
    computed: {
      isMaterialsRoute() {
        return !!(this.$route.path.indexOf('/material/') + 1)
      }
    },
    methods: {
      closeModal(disabled) {
        this.$emit('close-modal', disabled);
      },
      toggleMenu() {
        this.open = !this.open;
      }
    }
  }
</script>

<style lang="scss">
  .list-group-item {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none transparent;

    .nav-link.menu-link.menu-sub-link {
      padding-right: 20px;

      i {
        transition: all 0.15s linear;

        &.rotate-180 {
          transition: all 0.15s linear;
          transform: rotate(180deg);
        }
      }
    }

    .sub-links {
      ul.list-group {
        li.list-group-item {
          border: none;
  
        }
      }
    }

    .nav-link {
      &.menu-link {
        margin: 0;
        padding: 15px;
        opacity: 0.7;
        color: #707070;
        font-size: 15px;
        font-weight: 600;
        height: 50px;
        display: flex;
        align-items: center;

        &.router-link-active {
          background-color: #05a081;
          color: #FFFFFF;
        }
      }
    }
  }
</style>