<template lang="pug">
  .header-menu-modal
    .modal-header
      img.ml-auto.close-modal-btn( :src="closeButtonImage" @click="closeModal(false)")
    .modal-body
      ul.list-group
        template( v-for="link in links")
          SingleLink( :link="link" v-on:close-modal="closeModal")

</template>

<script>
import SingleLink from './SingleMenuLink'

export default {
  name: 'HeaderMenuModal',
  props: ['links'],
  components: {
    SingleLink
  },
  computed: {
    closeButtonImage() {
      return require('@/close_icon.png')
    }
  },
  methods: {
    closeModal(disabled) {
      document.body.classList.remove('hidden')
      if (!disabled) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss">
.header-menu--modal {
  border: unset;
  box-shadow: none;
  background-color: #f8f8f8;
  height: 100vh;
}

div.header-menu-modal {
  .modal-header {
    margin: 0 9px;
    padding: 19px 0;
    .title {
      text-transform: uppercase;
      font-weight: bold;
    }

    .close-modal-btn {
      width: 22px;
      height: 23px;
      cursor: pointer;
      opacity: 0.7;
      transition: opacity 0.15s linear;

      &:hover {
        opacity: 1;
        transition: opacity 0.15s linear;
      }
    }
  }

  div.modal-body {
    background: transparent;
    padding: 0;
    margin-top: 20px;
  }
}
</style>
