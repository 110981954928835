<template lang="pug">
  #preview-file-container.preview-file-container
    template( v-if="!isError")
      .preview-titles
        | {{ deputyInterest.title }}
        
      //.table-part
        #wrapper(v-html="file")
      tablePreview( :file="currentFileData" :classWithYears="false" v-if="currentFileData")
      .download-file
        button.btn.download-button( v-on:click="downloadFile")
          | Завантажити набір
    template( v-else)
      .preview-title
        | {{ isError }}
</template>

<script>
  import XLSX from 'xlsx'
  import tablePreview from '../../layout/table_preview/TablePreview'

  export default {
    name: "deputiesInterests",
    components: {
      tablePreview
    },
    data() {
      return {
        deputyInterest: {},
        file: '',
        isError: false
      }
    },
    created() {
      if (this.$route.params.id) {
        this.deputyInterestsRequest();
      }
    },
    watch: {
      $route(to, from) {
        this.deputyInterestsRequest();
      }
    },
    computed: {
      currentFileUrl() {
        return this.deputyInterest.file_url;
      },
      currentFileData() {
        return this.deputyInterest.table;
      }
    },
    methods: {
      deputyInterestsRequest() {
        if (this.$route.params.id !== "index") {
          this.$store.dispatch('deputiesInterests/getDeputyInterestFileInfo', this.$route.params.id).then(() => {
            this.isError = false;
            this.deputyInterest = this.$store.getters['deputiesInterests/getDeputyInterestFileInfo'];
            this.getFile();
          }).catch(() => {
            this.isError = this.$store.getters['deputiesInterests/getError'];
          });
        } else {
          this.isError = this.$store.getters['deputiesInterests/getError'];
        }
      },
      downloadFile() {
        let link = document.createElement('a');
        link.href = this.currentFileUrl;
        link.setAttribute('download', this.deputyInterest.file_name);
        document.body.appendChild(link);
        link.click();
      },
      getFile() {
        let that = this;
        fetch(this.currentFileUrl).then(function (response) {
          return response.arrayBuffer();
        }).then(function (array_buffer) {
          let data = new Uint8Array(array_buffer);
          let wb = XLSX.read(data, {type: 'array'});
          that.file = XLSX.write(wb, {type: 'string', bookType: 'html'});
        });
      }
    }
  }
</script>

<style lang="scss">
  .preview-file-container {
    height: calc(100% + 65px);
    margin-top: -65px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0 12px;

    .preview-titles, .preview-title {
      font-size: 18px;
      line-height: 65px;
      text-transform: uppercase;
    }

    .table-part {
      margin-bottom: 0;
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      color: #222222;
      padding: 12px 0;
      height: calc(86vh - 130px);

      #wrapper {
        /*max-height: 45vh;*/
        overflow: auto;
        height: 100%;

        &::-webkit-scrollbar-track {
          background-color: #b8b8b8;
        }

        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          width: 271px;
          background-color: #626262;
        }

        table {
          border-collapse: collapse;
          font-size: 10px;
          text-align: center;
        }

        table, th, td {
          border: 1px solid black;
        }
      }
    }

    .download-file {
      display: flex;
      justify-content: center;
      padding: 17px 0;

      .download-button {
        outline: 0 !important;
        border-color: initial;
        box-shadow: none;
        width: 185px;
        height: 31px;
        border-radius: 4px;
        background-color: #05a081;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.3s linear;
        color: white;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;

        ::-moz-focus-outer, ::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        &:hover {
          opacity: 1;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .preview-file-container {
      margin: 20px 0 0;
      padding: 0 10px;
      height: auto;

      .preview-titles{
        display: none;
      }

      .preview-title {
        font-size: 15px;
        text-align: center;
      }
      .download-file {
        padding: 30px 0 20px;

        .download-button {
          width: 100%;
          height: 46px;
          background-color:rgba(5,160,129,1);
          font-size: 14px;
        }
      }
    }
  }
  
</style>
