<template lang="pug">
  th(scope='col')
    .column-header( @click="changeSort" :title="object.name")
      .column-title
        span {{ truncate(object.name) }}
      .caret-container
        i.fas.fa-caret-up.d-block( :class="{disabled: asc}")
        i.fas.fa-caret-down.d-block( :class="{disabled: desc}")
</template>

<script>
export default {
  name: 'columnHeader',
  props: ['object'],
  data() {
    return {
      counter: 0
    }
  },
  computed: {
    asc() {
      return this.counter === 1
    },
    desc() {
      return this.counter === 2
    },
    sortMethod() {
      switch (this.counter) {
        case 1:
          return {
            sortType: 'asc',
            alias: this.object.alias,
            id: this.object.id
          }
        case 2:
          return {
            sortType: 'desc',
            alias: this.object.alias,
            id: this.object.id
          }
        default:
          return { sortType: '', alias: this.object.alias, id: this.object.id }
      }
    }
  },
  methods: {
    resetCounter() {
      this.counter = 0
    },
    changeSort() {
      this.counterUpdate()
      this.$emit('change-sort', this.sortMethod)
    },
    counterUpdate() {
      this.counter++
      this.counter = this.counter > 2 || this.counter < 0 ? 0 : this.counter
    },
    truncate(str) {
      const size = 27;
      return str.length <= size ? str : `${str.substr(0,size - 3)}...`
    }
  }
}
</script>

<style lang="scss">
.column-header {
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  min-width: 75px;

  .column-title,
  .caret-container {
    display: flex;
    flex-direction: column;
    height: 22px;
  }

  .column-title {
    max-height: 36px;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    span {
      line-height: 19px;
    }
  }

  .caret-container {
    margin-left: 5px;

    i {
      height: 7px;

      &.disabled {
        &:before {
          display: none;
        }
      }
    }
  }
}
</style>
