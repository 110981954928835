import axios from 'axios'

const actions = {
  getDeputyInterestFileInfo(context, payload) {
    let deputyInterestCouncilId = payload;
    return new Promise((resolve, reject) => {
      axios('/api/deputies_interests_file.json', {
        params: {
          id: deputyInterestCouncilId
        }
      }).then(response => {
        let deputyInterest = response.data;
        context.commit('SET_ERROR', false);
        context.commit('SET_DEPUTY_INTEREST', deputyInterest);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      });
    })
  }
};

const mutations = {
  SET_DEPUTY_INTEREST(context, payload) {
    context.deputyInterest = payload;
  },
  SET_ERROR(context, payload) {
    context.error = payload
  }
};

const state = {
  deputyInterest: {},
  error: 'ВІДСУТНІ ДАНІ ДЛЯ ВІДОБРАЖЕННЯ'
};

const getters = {
  getDeputyInterestFileInfo(state) {
    return state.deputyInterest
  },
  getError(state) {
    return state.error
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}
