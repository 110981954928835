<template lang="pug">
  .main-page-articles
    .block-title.d-block
      | Останні новини
    .row
      template( v-for="article in articles")
        .col-12.col-sm-6.col-lg-12
          .article
            .article-header
              .publication-date {{ article.createdAt }}
              router-link(:to="'/article/' + article.id" class="title" v-html="article.title")
            .article-body
              router-link(:to="'/article/' + article.id" tag="div" class="article-img")
                img( :src="article.image")
              .article-description( v-html="article.shortDescription.slice(0, 200)")
              tags(:tags="article.tags")
    .more-articles
      router-link.btn.show-more-articles-button( :to="'/articles'")
        | Більше новин
</template>

<script>
  import tags from  '../articles/Tags.vue'

  export default {
    name: "MainPageArticles",
    components: {
      tags
    },
    data () {
      return {
        articles: []
      }
    },
    created () {
      this.getMainPageArticles();
    },
    methods: {
      getMainPageArticles () {
        this.$store.dispatch('articles/getMainPageArticles').then(() => {
          this.articles = this.$store.getters['articles/getMainPageArticles'];
        });
      }
    }
  }
</script>

<style lang="scss">
  .main-page-articles {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0 12px;
    margin-top: -80px;

    .block-title {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: bold;
      padding: 26px 0;
      border-bottom: 1px solid #eeeeee;
    }

    .article {
      padding: 30px 0;
      border-bottom: 1px solid #eeeeee;

      .article-header {
        .title {
          color: #222222;
          font-size: 16px;
          font-weight: bold;
          text-decoration: none;
        }

        .publication-date {
          color: #888888;
          font-size: 12px;
          font-weight: bold;
          line-height: 25px;
        }
      }

      .article-body {
        .article-img {
          margin: 0px -12px;
          padding: 15px 0;
          cursor: pointer;

          img {
            width: 100%;
          }
        }

        .article-description {
          color: #555555;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .more-articles {
      display: flex;
      justify-content: center;
      padding: 17px 0;

      .show-more-articles-button {
        outline: 0 !important;
        border-color: initial;
        box-shadow: none;
        width: 180px;
        height: 31px;
        border-radius: 4px;
        background-color: #05a081;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.3s linear;
        color: #ffffff;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        ::-moz-focus-outer, ::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        &:hover {
          opacity: 1;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .main-page-articles {
      margin: 30px 0;

      .article {
        background-color: #ffffff;
        border-radius: 4px;
        padding: 12px 0;
        border: none;
      }
    }
  }
</style>
