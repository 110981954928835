<template lang="pug">
  .container-fluid#company_products
    .background( :style="{'background-image': 'url(' + backgroundImage + ')'}")
      .container
        .row
          .col-md-12
            breadcrumbs
          .col-md-12.overflow-auto
            .submenu
              nav.navbar.navbar-expand-lg.navbar-light.w-100.pl-0
                ul.navbar-nav.mt-2.mt-lg-0
                  template( v-for="link in subMenuLinks")
                    li.nav-item
                      router-link.nav-link.menu-link( :to="subMenuLink(link)" v-if="link.node_type !== 'link'")
                        | {{ link.title }}
                      a.nav-link.menu-link( :href="'//' + link.outer_link" target="_blank" v-else)
                        | {{ link.title }}
    .container
      .row
        template( v-if="$route.params.id")
          .col-12.councils-select
            v-select.form-control.custom-select( :options="councils" label="title" v-model="selected" @input="changeRoute($route.name)")
          .col-md-4.councils-list
            template( v-for="council in councils")
              router-link.council-title.d-block( :to="{ name: $route.name, params: { id: council.id }}" )
                | {{ council.title }}
          .col-12.col-md-8
            router-view
        template( v-else)
          .col-md-12
            router-view
</template>

<script>
  import vSelect from 'vue-select'
  import breadcrumbs from '../layout/Breadcrumbs'

  export default {
    name: "CompanyProducts",
    components: {
      breadcrumbs,
      "v-select": vSelect
    },
    data() {
      return {
        councils: [],
        selected: null,
        componentAlias: 'company_products'
      }
    },
    created() {
      this.getCouncils();
      this.getReportingPeriods();
    },
    computed: {
      backgroundImage() {
        return require('@/body_top_background.png')
      },
      subMenuLinks() {
        let link = [];
        this.$store.getters['menus/getMenus'].forEach(item => {
          if (item.alias === 'company_products') {
            link = item.sub_menus;
          }
        });
        return link
      }
    },
    methods: {
      getReportingPeriods() {
        this.$store.dispatch('reportingPeriods/getReportingPeriod').catch(error => {
          console.log(error)
        });
      },
      getCouncils() {
        this.$store.dispatch('councils/getCouncilsInfo').then(() => {
          this.councils = this.$store.getters['councils/getCouncilsInfo'];
          this.selected = this.councils[0];
        })
      },
      changeRoute (router_name) {
        if (this.selected) {
          this.$router.push({ name: router_name, params: { id: this.selected.id } })
        }
      },
      subMenuLink(link) {
        return link.alias === this.componentAlias ?
          '/' + this.componentAlias + '/' + this.$route.params.id :
          '/' + this.componentAlias + '/' + (
            link.node_type === 'static' ?
              link.alias + '/' +this.$route.params.id :
              'advanced_info/' + link.alias
          )
      }
    }
  }
</script>

<style lang="scss">
  @media (max-width: 991px) {
    #company_products {
      .background {
        .submenu {
          .navbar {
            .navbar-nav {
              flex-direction: unset;
              -ms-flex-direction: unset;

              .nav-item {
                .nav-link.menu-link {
                  margin-right: 20px;
                }
              }
            }
          }
        }
      }
    }
  }

  #company_products {
    padding-bottom: 20px;
    background-color: #f8f8f8;

    .background {
      height: 215px;
      margin: 0 -15px;

      .breadcrumbs {
        color: #ffffff;
        font-size: 12px;
        padding: 0 16px;
        margin: 30px 0 20px;
      }

      .submenu {
        font-size: 16px;
        line-height: 20px;

        .navbar {
          .navbar-nav {
            .nav-item {
              .nav-link.menu-link {
                opacity: 0.8;
                color: #ffffff;
                cursor: pointer;
                padding-left: 0;
                margin-right: 30px;
                padding-bottom: 6px;
                text-transform: uppercase;

                &.router-link-active {
                  opacity: 1;
                  cursor: default;
                  border-bottom: 2px solid #fff;
                }
              }
            }
          }
        }
      }
    }

    .councils-list {
      overflow: auto;
      /*max-height: 745px;*/
      margin-top: -65px;
      padding: 12px 20px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
      height: 86vh;
      overflow: auto;

      &::-webkit-scrollbar-track {
        background-color: #b8b8b8;
      }

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        width: 271px;
        background-color: #626262;
      }

      .council-title {
        color: #555555;
        cursor: pointer;
        font-size: 14px;
        line-height: 36px;

        &:hover {
          text-decoration: none;
        }
      }

      .router-link-active {
        color: #05a081;
      }

      .router-link-exact-active {
        font-weight: bold;
      }

      &::-webkit-scrollbar-track {
        background-color: #b8b8b8;
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        width: 271px;
        background-color: #626262;
      }
    }

    .councils-select {
      display: none;
      margin-top: -65px;

      .custom-select {
        padding: 0;
        border: none;
        font-size: 14px;
        background-color: #fff;
        background-image: none;

        &.selected-councils {
          cursor: pointer;
          overflow: hidden;
          padding-right: 5px;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:after {
            top: 0;
            right: 22px;
            content: '';
            width: 50px;
            height: 100%;
            position: absolute;
            background: rgba(237, 237, 237, 0);
            background: -moz-linear-gradient(left, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
            background: -webkit-linear-gradient(left, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
            background: -o-linear-gradient(left, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
            background: -ms-linear-gradient(left, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
            background: linear-gradient(to right, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#ffffff', GradientType=1);
          }

          .selected-council {
            margin: 0;
            height: 100%;
            border: none;
            padding: 8px;
            max-height: 35px;
            line-height: 35px;
            background-color: transparent;
          }
        }

        .dropdown-toggle {
          padding: 0;
          border: none;

          &:after {
            display: none;
          }

          .vs__actions {
            .clear {
              font-size: 18px;
            }

            .open-indicator {
              padding-bottom: 1px;

              &:before {
                width: 6px;
                height: 6px;
                border-width: 2px 2px 0 0;
              }
            }
          }

          .vs__selected-options {
            padding: 0;

            input[type="search"] {
              margin: 0;
              border: none;
            }

            .selected-tag {
              margin: 0;
              border: none;
              padding: 8px;
              height: 100%;
              max-height: 35px;
              background-color: transparent;
            }
          }
        }

        .dropdown-menu {
          top: 30px;
          border: none;

          li {
            * {
              font-size: 14px;
            }
          }

          a {
            &:hover {
              background-color: grey;
            }
          }

          .highlight {
            a {
              background-color: grey;
            }
          }
        }
      }
    }
  }

  @import "../assets/stylesheets/company_products.scss";

  @media screen and (max-width: 767px) {
    #company_products {
      #reporting_periods {
        width: 85%;
      }
      .councils-list {
        display: none;
      }

      .councils-select {
        display: inline-block;
      }

      .company-product {
        margin-top: 5px;
      }
    }
  }
</style>
