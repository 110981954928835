const config = {
    mobile: 'screen and (min-width : 320px) and (max-width : 767px)',
};


function isMobile() {
    return window.matchMedia(config.mobile).matches
}


export {isMobile}