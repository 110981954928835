import axios from 'axios'

const actions = {
  getMenus: (context, payload) => {
    return new Promise((resolve, reject) => {
      axios.get('/api/menus').then( response => {
        let menus = response.data;
        context.commit('SET_MENUS', menus);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  }
};

const mutations = {
  SET_MENUS: (context, payload) => {
    context.menus = payload;
  }
};

const state = {
  menus: []
};

const getters = {
  getMenus: state => {
    return state.menus
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}
