<template lang="pug">
  .company-product.recommendations
    reporting-periods( :title="isError === false ? recommendation.title : false" v-on:dataByYear="recommendationsRequest")
    template(v-if="!isError")
      #wrapper_pdf( v-if="showTemplate")
        embed(:src="recommendation.file_url" width="100%")
      spiner( v-else)
    template(v-else)
      .preview-title
        | {{ isError }}
</template>

<script>
  import reportingPeriods from '../company_products/ReportingPeriods.vue'
  import spiner from '../../layout/Spiner'

  export default {
    name: "Recommendations",
    components: {
      reportingPeriods,
      spiner
    },
    data () {
      return {
        timerDelay: 500,
        isError: false,
        showTemplate: true,
        // recommendation: {},
        year: new Date().getFullYear()
      }
    },
    created () {
      if (this.$route.params.id) {
        this.recommendationsRequest();
      }
    },
    watch: {
      $route (to, from) {
        this.recommendationsRequest(this.year);
      }
    },
    computed: {
      recommendation () {
        return this.$store.getters['companyProducts/getRecommendation']
      }
    },
    methods: {
      recommendationsRequest (year) {
        if (this.$route.params.id !== "index") {
          this.showTemplate = false;
          if (year) {
            this.year = year;
          } else {
            this.year = this.year - 1;
          }
          let params = { year: this.year, id: this.$route.params.id };
          this.$store.dispatch('companyProducts/getRecommendation', params).then(() => {
            setTimeout(() => {
              this.showTemplate = true;
            }, this.timerDelay);
            this.isError = false;
          }).catch(() => {
            this.isError = this.$store.getters['companyProducts/getError'];
          });
        }
      }
    }
  }
</script>
