<template lang="pug">
  .container-fluid#articles-container
    router-view
</template>

<script>
  export default {
    name: "Articles"
  }
</script>

<style lang="scss">
  #articles-container {
    background-color: #f8f8f8;
    padding-bottom: 15px;
  }
</style>
