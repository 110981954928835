import axios from 'axios'

const actions = {
  getAnalyticalReports(context, payload) {
    let id = payload.id;
    let year = payload.year;
    return new Promise((resolve, reject) => {
      axios('/api/analytical_report.json', {
        params: {
          id: id,
          year: year
        }
      }).then(response => {
        let analyticalReports = response.data.analytical_reports;
        context.commit('SET_ERROR', false);
        context.commit('SET_ANALYTICAL_REPORT', analyticalReports);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      });
    })
  },
  getRecommendation(context, payload) {
    let id = payload.id;
    let year = payload.year;
    return new Promise((resolve, reject) => {
      axios('/api/recommendation.json', {
        params: {
          id: id,
          year: year
        }
      }).then(response => {
        let recommendation = response.data;
        context.commit('SET_ERROR', false);
        context.commit('SET_RECOMMENDATION', recommendation);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      });
    })
  },
  getInfographics(context, payload) {
    let id = payload.id;
    let year = payload.year;
    return new Promise((resolve, reject) => {
      axios('/api/infographics.json', {
        params: {
          id: id,
          year: year
        }
      }).then(response => {
        let infographics = response.data;
        context.commit('SET_ERROR', false);
        context.commit('SET_INFOGRAPHICS', infographics);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      });
    })
  },
  getComparativeInfographics(context, payload) {
    let id = payload.id;
    let year = payload.year;
    return new Promise((resolve, reject) => {
      axios('/api/infographics.json', {
        params: {
          id: id,
          year: year
        }
      }).then(response => {
        let infographics = response.data;
        context.commit('SET_ERROR', false);
        context.commit('SET_INFOGRAPHICS', infographics);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      });
    })
  },
  getCriteriaEvaluations(context, payload) {
    let id = payload.id;
    let year = payload.year;
    return new Promise((resolve, reject) => {
      axios('/api/criteria_evaluations.json', {
        params: {
          id: id,
          year: year
        }
      }).then(response => {
        let criteriaEvaluations = response.data;
        context.commit('SET_ERROR', false);
        context.commit('SET_CRITERIA_EVALUATIONS', criteriaEvaluations);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      });
    })
  },
  getExpertEvaluations(context, payload) {
    let id = payload.id;
    let year = payload.year;
    return new Promise((resolve, reject) => {
      axios('/api/expert_evaluations.json', {
        params: {
          id: id,
          year: year
        }
      }).then(response => {
        let expertEvaluations = response.data;
        context.commit('SET_ERROR', false);
        context.commit('SET_EXPERT_EVALUATIONS', expertEvaluations);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      });
    })
  },
  getContacts(context, payload) {
    let id = payload;
    return new Promise((resolve, reject) => {
      axios('/api/contacts.json', {
        params: {
          id: id
        }
      }).then(response => {
        let contacts = response.data;
        context.commit('SET_ERROR', false);
        context.commit('SET_CONTACTS', contacts);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      });
    })
  },
  getSchedule(context, payload) {
    let id = payload;
    return new Promise((resolve, reject) => {
      axios('/api/schedule.json', {
        params: {
          id: id
        }
      }).then(response => {
        let schedule = response.data;
        context.commit('SET_ERROR', false);
        context.commit('SET_SCHEDULE', schedule);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      });
    })
  }
};

const mutations = {
  SET_ANALYTICAL_REPORT(context, payload) {
    context.analyticalReports = payload;
  },
  SET_RECOMMENDATION(context, payload) {
    context.recommendation = payload;
  },
  SET_INFOGRAPHICS(context, payload) {
    context.infographics = payload;
  },
  SET_CRITERIA_EVALUATIONS(context, payload) {
    context.criteriaEvaluations = payload;
  },
  SET_EXPERT_EVALUATIONS(context, payload) {
    context.expertEvaluations = payload;
  },
  SET_CONTACTS(context, payload) {
    context.contacts = payload;
  },
  SET_SCHEDULE(context, payload) {
    context.schedule = payload;
  },
  SET_ERROR(context, payload) {
    context.error = payload
  }
};

const state = {
  analyticalReports: {},
  recommendation: {},
  infographics: {},
  criteriaEvaluations: {},
  expertEvaluations: {},
  contacts: {},
  schedule: {},
  error: false
};

const getters = {
  getAnalyticalReports(state) {
    return state.analyticalReports
  },
  getRecommendation(state) {
    return state.recommendation
  },
  getInfographics(state) {
    return state.infographics
  },
  getComparativeInfographics(state) {
    return state.infographics
  },
  getCriteriaEvaluations(state) {
    return state.criteriaEvaluations
  },
  getExpertEvaluations(state) {
    return state.expertEvaluations
  },
  getContacts(state) {
    return state.contacts
  },
  getSchedule(state) {
    return state.schedule
  },
  getError(state) {
    return state.error
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}
