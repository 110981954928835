import axios from "axios"

const actions = {
  setCurrentCouncilId(context, payload) {
    context.commit("SET_CURRENT_COUNCIL_ID", payload);
  },
  getCouncilDeputies(context, payload) {
    let councilId = payload;
    return new Promise((resolve, reject) => {
      axios.get("/api/council_deputies/" + councilId + ".json").then(response => {
        let councilDeputies = response.data.council_deputies;
        context.commit("SET_COUNCIL_DEPUTIES", councilDeputies);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  },
  getCouncilsInfo(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/councils_info.json').then(response => {
        let councilsInfo = response.data;
        context.commit("SET_COUNCILS_INFO", councilsInfo);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  },
  getCouncils(context, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/api/councils.json').then(response => {
        let councils = response.data;
        context.commit("SET_COUNCILS", councils);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  },
  getCouncilMap(context, payload) {
    let councilId = payload;
    return new Promise((resolve, reject) => {
      axios.get('/api/council_map.json', {
        params: {
          id: councilId
        }
      }).then(response => {
        let council_map = response.data.council_map;
        context.commit('SET_ERROR', false);
        context.commit("SET_COUNCIL_MAP", council_map);
        resolve();
      }).catch(error => {
        context.commit('SET_ERROR', error.response.data.error);
        reject();
      })
    })
  }
};

const mutations = {
  SET_CURRENT_COUNCIL_ID(context, payload) {
    context.current_council_id = payload;
  },
  SET_COUNCIL_DEPUTIES(context, payload) {
    context.councilDeputies = payload;
  },
  SET_COUNCILS_INFO(context, payload) {
    context.councilsInfo = payload;
  },
  SET_COUNCILS(context, payload) {
    context.councils = payload;
  },
  SET_COUNCIL_MAP(context, payload) {
    context.council_map = payload;
  },
  SET_ERROR(context, payload) {
    context.error = payload
  }
};

const state = {
  councilDeputies: [],
  councilsInfo: [],
  councils: [],
  council_map: [],
  current_council_id: null,
  error: false
};

const getters = {
  getCurrentCouncilId(state) {
    return state.current_council_id
  },
  getCouncilDeputies(state) {
    return state.councilDeputies
  },
  getCouncilsInfo(state) {
    return state.councilsInfo
  },
  getCouncils(state) {
    return state.councils
  },
  getCouncilMap(state) {
    return state.council_map
  },
  getError(state) {
    return state.error
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}