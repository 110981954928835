<template lang="pug">
  .deputy-reports-container( v-if="deputies.length > 0")
    .deputy-reports-page-title
      | Звіти депутатів
    .table-responsive.table-of-deputies
      table.table.table-hover.table-striped
        thead
          tr
            th(scope='col')
            column-head( :object="sortFullNameObject" :ref="sortFullNameObject.alias" v-on:change-sort="changeSort")
            template( v-for="reportingPeriod in reportingPeriods")
              th.reporting-period-row
                span
                  | {{ reportingPeriod.year }}
        tbody
          template( v-for="deputy in deputiesForTable")
            tr
              td
                img.deputy-image( :src="deputy.image")
              td
                router-link( :to="'/councils/deputy/' + deputy.id" class="show-deputy")
                  | {{ deputy.full_name }}
              template( v-for="report in deputy.reports" v-if="deputy.reports")
                td.check-marks-row
                  template
                    span.check-marks
                      i.fas.fa-check( v-if="report.reporting_period")
                      i.fas.fa-times( v-else)
                    a.download-report-link( v-if="report.file && report.reporting_period" :href="report.file" download)
                      i.far.fa-file-word
              template( v-if="!deputy.reports.length")
                td.check-marks-row
                  span.check-marks
                    i.fas.fa-times
    .more-results
      button.btn.show-more-results-button( @click="showMoreDeputies()" :class="{ disabled: disableMoreDeputiesButton }")
        | Більше результатів
  .deputy-reports-container( v-else)
    .deputy-reports-page-title
      | ВІДСУТНІ ДАНІ ДЛЯ ВІДОБРАЖЕННЯ

</template>

<script>
  import columnHead from '../home/ColumnHeader'

  export default {
    name: "DeputiesReports",
    components: {
      columnHead
    },
    data() {
      return {
        timerDelay: 500,
        timer: null,
        sortFullNameObject: {
          name: "Ім'я депутата",
          alias: "full_name"
        },
        query: {
          alias: '',
          sortType: ''
        },
        reportingPeriods: [],
        deputies: false,
        sortedDeputies: [],
        perPage: 10
      }
    },
    created() {
      this.getCouncilDeputies();
      this.getReportingPeriods();
    },
    watch: {
      query: {
        handler(oldValue, newValue) {
          this.sortDeputiesWithDelay();
        },
        deep: true
      },
      $route(to, from) {
        this.getCouncilDeputies();
      }
    },
    computed: {
      deputiesForTable() {
        return (this.query.sortType.length ? this.sortedDeputies : this.deputies).slice(0, this.perPage)
      },
      disableMoreDeputiesButton() {
        return this.perPage > this.deputies.length
      }
    },
    methods: {
      isYearValid(year, repPeriod) {
        return year === repPeriod
      },
      isFileExists(file) {
        return file.length > 0
      },
      showMoreDeputies() {
        this.perPage += 10;
      },
      sortDeputiesWithDelay(method) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }

        this.timer = setTimeout(() => {
          this.changeSort(method);
        }, this.timerDelay)
      },
      changeSort(method) {
        Object.assign(this.query, method);
        Object.assign(this.sortedDeputies, this.deputies);
        this.sortedDeputies.sort((a, b) => a[this.query.alias].localeCompare(b[this.query.alias], 'ua', {ignorePunctuation: true}));
        if (this.query.sortType === 'asc') {
          this.sortedDeputies.reverse()
        }
      },
      getCouncilDeputies() {
        if (this.$route.params.id !== "index") {
          this.$store.dispatch('councils/getCouncilDeputies', this.$route.params.id).then(() => {
            this.deputies = this.$store.getters['councils/getCouncilDeputies'];
          });
        }
      },
      getReportingPeriods() {
        this.$store.dispatch('reportingPeriods/getReportingPeriod').then(() => {
          this.reportingPeriods = this.$store.getters['reportingPeriods/getReportingPeriods'];
        });
      }
    }
  }

</script>

<style lang="scss">
  .deputy-reports-container {
    margin-top: -65px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 0 12px;
    height: 86vh;

    .deputy-reports-page-title {
      font-size: 18px;
      line-height: 65px;
      text-transform: uppercase;
    }

    .table-of-deputies {
      height: calc(86vh - 130px);
      overflow: auto;

      &::-webkit-scrollbar-track {
        background-color: #b8b8b8;
      }

      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        width: 271px;
        background-color: #626262;
      }

      table {
        margin-bottom: 0;
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        color: #222222;

        * {
          border: none;
        }

        thead {
          th {
            &.reporting-period-row {
              text-align: center;
            }
          }
        }

        tbody {
          tr {
            th {
              font-weight: normal;
            }

            td {
              vertical-align: middle;

              &.check-marks-row {
                text-align: center;

                .check-marks {
                  .fa-check {
                    color: green;
                  }

                  .fa-times {
                    color: red;
                  }
                }

                .download-report-link {
                  color: #3079ef;
                  display: block;
                  cursor: pointer;
                }
              }

              .show-deputy {
                color: #212529;
              }

              .deputy-image {
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }

    .more-results {
      display: flex;
      justify-content: center;
      padding: 17px 0;

      .show-more-results-button {
        outline: 0 !important;
        border-color: initial;
        box-shadow: none;
        width: 180px;
        height: 31px;
        border-radius: 4px;
        background-color: #05a081;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.3s linear;
        color: white;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;

        ::-moz-focus-outer, ::-moz-focus-inner {
          border: 0;
          padding: 0;
        }

        &:hover {
          opacity: 1;
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.5;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .deputy-reports-container {
      margin-top: 20px;
    }
  }
</style>
