const actions = {
  getMainPageModalFiltersAll(context, payload) {
    context.commit('SET_MAIN_PAGE_MODAL_FILTERS_ALL', payload)
  },
  getMainPageModalFiltersOnlyTotal(context, payload) {
    context.commit('SET_MAIN_PAGE_MODAL_FILTERS_ONLY_TOTAL', payload)
  },
  getMainPageModalCouncilsAll(context, payload) {
    context.commit('SET_MAIN_PAGE_MODAL_COUNCILS_ALL', payload)
  },
  getMainPageModalSendRequestAccess(context, payload) {
    context.commit('SET_MAIN_PAGE_MODAL_SEND_REQUEST_ACCESS', payload);
  }
};

const mutations = {
  SET_MAIN_PAGE_MODAL_FILTERS_ALL(context, payload) {
    context.mainPage.modals.checkAllFilters = payload
  },
  SET_MAIN_PAGE_MODAL_FILTERS_ONLY_TOTAL(context, payload) {
    context.mainPage.modals.checkOnlyTotal = payload
  },
  SET_MAIN_PAGE_MODAL_COUNCILS_ALL(context, payload) {
    context.mainPage.modals.checkAllCouncils = payload
  },
  SET_MAIN_PAGE_MODAL_SEND_REQUEST_ACCESS(context, payload) {
    context.mainPage.modals.sendRequestAfterCloseAccess = payload
  }
};

const state = {
  mainPage: {
    modals: {
      checkAllFilters: true,
      checkOnlyTotal: false,
      checkAllCouncils: true,
      sendRequestAfterCloseAccess: false
    }
  }
};

const getters = {
  getMainPageModalFiltersAll(state) {
    return state.mainPage.modals.checkAllFilters
  },
  getMainPageModalFiltersOnlyTotal(state) {
    return state.mainPage.modals.checkOnlyTotal
  },
  getMainPageModalCouncilsAll(state) {
    return state.mainPage.modals.checkAllCouncils
  },
  getMainPageModalSendRequestAccess(state) {
    return state.mainPage.modals.sendRequestAfterCloseAccess
  }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}
