import Home from '../components/Home'
import DeputyShow from '../components/DeputyShow'
import ArticleShow from '../components/ArticleShow'
import AboutDeputies from '../components/AboutDeputies'
import ReceptionMaps from '../components/about_deputies/ReceptionMaps.vue'
import DeputiesInterests from '../components/about_deputies/DeputiesInterests'
import DeputiesReports from '../components/about_deputies/DeputiesReports.vue'
import Contacts from "../components/about_deputies/Contacts";
import Schedules from "../components/about_deputies/Schedules";
import Councils from '../components/Councils'
import Articles from '../components/Articles';
import ArticlesIndex from '../components/articles/Index'
import MaterialShow from "../components/materials/Show";
import CompanyProducts from "../components/CompanyProducts";
import AnalyticalReports from "../components/company_products/AnalyticalReports";
import Recommendations from "../components/company_products/Recommendations";
import Infographics from "../components/company_products/Infographics";
import CriteriaEvaluations from "../components/company_products/CriteriaEvaluations";
import ExpertEvaluations from "../components/company_products/ExpertEvaluations";
import DeputyMaterial from "../layout/Material"
import PageNotFound from "../layout/PageNotFound"

const home = {
  path: '/',
  name: 'Home',
  component: Home
};

const deputiesInterests = {
  path: '/deputies_more',
  redirect: '/deputies_more/deputies_interests/index',
  name: 'AboutDeputies',
  component: AboutDeputies,
  children: [
    {
      path: '/deputies_more/advanced_info/:alias',
      component: DeputyMaterial,
      name: 'DeputiesMaterial'
    },
    {
      path: '/deputies_more/deputies_interests/:id',
      component: DeputiesInterests,
      name: 'DeputiesInterests',
    },
    {
      path: '/deputies_more/reception_maps/:id',
      component: ReceptionMaps,
      name: 'ReceptionMaps',
    },
    {
      path: '/deputies_more/deputies_reports/:id',
      component: DeputiesReports,
      name: 'DeputiesReports',
    },
    {
      path: '/deputies_more/contacts/:id',
      component: Contacts,
      name: 'Contacts',
    },
    {
      path: '/deputies_more/schedules/:id',
      component: Schedules,
      name: 'Schedules',
    }
  ]
};

const deputyShow = {
  path: '/councils/deputy/:id',
  component: DeputyShow,
  name: 'DeputyShow'
};

const councils = {
  path: '/councils',
  component: Councils,
  name: 'Councils'
};

const articles = {
  path: '/articles',
  component: Articles,
  children: [
    {
      path: '',
      component: ArticlesIndex,
      name: 'ArticlesIndex',
    }
  ]
};

const articleShow = {
  path: '/article/:id',
  component: ArticleShow,
};

const materialShow = {
  path: '/material/:alias',
  component: MaterialShow,
  name: 'ShowMaterial'
};

const companyProducts = {
  path: '/company_products',
  redirect: '/company_products/analytical_reports/1',
  name: 'CompanyProducts',
  component: CompanyProducts,
  children: [
    {
      path: '/company_products/advanced_info/:alias',
      component: DeputyMaterial,
      name: 'CompanyMaterial'
    },
    {
      path: '/company_products/analytical_reports/:id',
      component: AnalyticalReports,
      name: 'AnalyticalReports',
    },
    {
      path: '/company_products/recommendations/:id',
      component: Recommendations,
      name: 'Recommendations',
    },
    {
      path: '/company_products/infographics/:id',
      component: Infographics,
      name: 'Infographics',
    },
    {
      path: '/company_products/criteria_evaluations/:id',
      component: CriteriaEvaluations,
      name: 'CriteriaEvaluations',
    },
    {
      path: '/company_products/expert_evaluations/:id',
      component: ExpertEvaluations,
      name: 'ExpertEvaluations',
    }
  ]
};

const pageNotFound = {
  path: '*',
  component: PageNotFound,
  meta: {
    is404: true
  },
};

const path = [
  home,
  deputiesInterests,
  deputyShow,
  councils,
  articles,
  articleShow,
  materialShow,
  companyProducts,
  pageNotFound
];

export default path
