<template lang="pug">
  canvas#myChart
</template>

<script>
  import Chart from 'chart.js'
  import {isMobile} from '../../../utils/mobileDetect'
  export default {
    name: "ChartDesktop",
    props: {
      deputy: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        chart: null
      }
    },
    watch: {
      deputy: {
        handler () {
          this.fixValues();
          this.viewChart();
        },
        deep: true
      }
    },
    computed: {
      yScaleAxisFontSize () {
        return this.isMobile() ? 6 : 12
      },
      toolTipsFontSize () {
        return (this.isMobile() ? 8 : 12) + 'px'
      },
      toolTipsMaxWidth () {
        return this.isMobile() ? '200px' : 'unset'
      }
    },
    mounted () {
      this.fixValues();
      this.viewChart();
    },
    methods: {
      isMobile,
      fixValues () {
        for (let i = 0; i < this.deputy.criteria_values.length; i++) {
          if (this.deputy.criteria_values[i] < 1) this.deputy.criteria_values[i] = 0.2
        }
      },
      viewChart() {
        let that = this;
        let ctx = document.getElementById('myChart');
        if (this.chart) this.chart.destroy();
        this.chart = new Chart(ctx, {
          type: 'horizontalBar',
          responsive: true,
          data: {
            labels: this.deputy.criteria_names,
            datasets: [{
              label: '',
              data: this.deputy.criteria_values.concat(''),
              backgroundColor: this.deputy.criteria_colors,
              borderSkipped: true,
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              yAxes: [{
                display: true,
                maxBarThickness: 5,
                ticks: {
                  fontSize: that.yScaleAxisFontSize
                }
              }],
              xAxes: [{
                display: true
              }]
            },
            title: {
              display: false,
            },
            legend: {
              display: false
            },
            tooltips: {
              enabled: false,
              custom: function (tooltipModel) {
                let tooltipEl = document.getElementById('chartjs-tooltip');
                if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'chartjs-tooltip';
                  tooltipEl.innerHTML = '<table></table>';
                  document.body.appendChild(tooltipEl);
                }

                if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }

                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                  tooltipEl.classList.add('no-transform');
                }

                function getBody(bodyItem) {
                  return bodyItem.lines;
                }

                if (tooltipModel.body) {
                  let index = tooltipModel.dataPoints[0].index;
                  let body = that.deputy.criteria_explanations[index];
                  let innerHtml = '<tbody>';
                  let style = 'padding: 10px; background: #ffffff; border: 1px solid black; border-radius: 5px;';
                  innerHtml += '<tr><td>' + '<span style="' + style + '">' + body + '</span></td></tr>';
                  innerHtml += '</tbody>';
                  let tableRoot = tooltipEl.querySelector('table');
                  tableRoot.innerHTML = innerHtml;
                }

                let position = this._chart.canvas.getBoundingClientRect();
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left = position.left + window.pageXOffset + (that.isMobile() ? 0 : (tooltipModel.caretX - 500)) + 'px';
                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - 35 + 'px';
                tooltipEl.style.fontFamily = 'Open Sans';
                tooltipEl.style.fontSize = that.toolTipsFontSize;
                tooltipEl.style.maxWidth = that.toolTipsMaxWidth;
                tooltipEl.style.pointerEvents = 'none';
              }
            }
          }
        });
      }
    }
  }
</script>
