import axios from "axios"

const actions = {
  getMaterial(context, payload) {
    let link = payload;
    return new Promise((resolve, reject) => {
      axios.get('/api/material/' + link + '.json').then(response => {
        let material = response.data.material;
        context.commit('SET_MATERIAL', material);
        resolve();
      }).catch(error => {
        reject(error.response.data.error);
      })
    })
  },
  // getMaterialsAliases(context, payload) {
  //   return new Promise((resolve, reject) => {
  //     axios.get('/api/materials_aliases.json').then(response => {
  //       let aliases = response.data;
  //       context.commit('SET_MATERIALS_ALIASES', aliases);
  //       resolve();
  //     }).catch(error => {
  //       reject(error.response.data.error);
  //     })
  //   })
  // }
};

const mutations = {
  SET_MATERIAL(context, payload) {
    context.material = payload;
  },
  // SET_MATERIALS_ALIASES(context, payload) {
  //   context.aliases = payload;
  // }
};

const state = {
  material: {},
  // aliases: []
};

const getters = {
  getMaterial(state) {
    return state.material
  },
  // getMaterialsAliases(state) {
  //   return state.aliases
  // }
};

export default {
  actions,
  mutations,
  state,
  getters,
  namespaced: true
}