<template lang="pug">
  .tags
    template(v-for="(tag, index) in tags")
      .tag(@click="articlesByTag(tag.title)")
        | {{ tag.title }}
        span.coma(v-if="index !== tags.length - 1")
          | ,
</template>

<script>
  export default {
    name: "Tags",
    props: ['tags'],
    methods: {
      articlesByTag (tag) {
        this.$router.push({ path: '/articles', query: { tag: tag } })
      }
    }
  }
</script>

<style lang="scss">
  .tags {
    padding-left: 15px;
    margin: 8px 0;

    .tag {
      color: #888888;
      font-size: 14px;
      margin-right: 5px;
      display: inline-block;
      font-family: 'Open Sans', sans-serif;

      &:hover {
        color: #05a081;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 767px) {
    .tags {
      padding-left: 0;
      line-height: 15px;
      padding: 0 10px;
      margin: 0;
      .tag {
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
</style>
