<template lang="pug">
  .container-fluid.deputy-articles
    .row
      template(v-for="article in articles")
        .article.col-sm-12.col-md-4
          .article-header
            router-link(:to="'/article/' + article.id" class="title" v-html="article.title")
            .publication-date(v-html="article.createdAt")
          .article-body
            router-link(:to="'/article/' + article.id" tag="div" class="article-img")
              img(:src="article.image")
            .article-description(v-html="article.shortDescription.slice(0, 190)")
            tags(:tags="article.tags")
      .article.col-sm-12.col-md-4(v-if="isMobile()")
        .one-block.first
          .deputy-data-header
            span
            | Графік роботи
          .deputy-data-body
            #wrapper-file-mobile(v-html="file == '' ? 'Файл для данного депутата не завантажено' : file")
            .separator
            a.view-on-map(:href="map_url")
              | Дивитись на карті
              img(:src="arrow")      
  </template>

<script>
  import tags from  '../articles/Tags'
  import {isMobile} from '../../utils/mobileDetect'
  export default {
    name: "DeputyArticles",
    components: {
      tags
    },
    props: {
      file:{
        type: String,
        default: ''
      }
    },
    data () {
      return {
        deputy: {},
        articles: []
      }
    },
    created () {
      this.deputy = this.$store.getters['deputies/getDeputy'];
      this.getDeputyArticles();
    },
    computed: {
      arrow() {
        return require('@/arrow-right.svg')
      },
      map_url() {
        if (this.deputy) {
          return '/deputies_more/reception_maps/' + this.deputy.council_id
        }
      }
    },
    methods: {
      isMobile,
      getDeputyArticles () {
        this.$store.dispatch('articles/getDeputyArticles', this.deputy.id).then(() => {
          this.articles = this.$store.getters['articles/getDeputyArticles'];
        });
      }
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css?family=Open+Sans');

  .deputy-articles {
    .article {
      padding: 0;
      flex: 0 0 32%;
      margin: 0 auto;
      border-radius: 4px;
      padding-bottom: 15px;
      background-color: #ffffff;
      margin: 10px 6.2px;
      .article-header {
        .title {
          width: 79%;
          height: 65px;
          color: #222222;
          font-size: 16px;
          line-height: 1.2;
          display: inline-block;
          text-decoration: none;
          padding: 16px 0 15px 19px;
          font-family: 'Open Sans - Semibold', sans-serif;
        }

        .publication-date {
          float: right;
          color: #888888;
          font-size: 12px;
          padding-top: 15px;
          padding-right: 10px;
          display: inline-block;
          font-family: 'Open Sans', sans-serif;
        }
      }

      .article-body {
        .article-img {
          img {
            width: 100%;
            height: auto;
          }
        }

        .article-description {
          height: 120px;
          color: #555555;
          font-size: 14px;
          line-height: 20px;
          padding-top: 10px;
          padding-left: 15px;
          font-family: 'Open Sans', sans-serif;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .deputy-articles {
      /*padding: 10px;*/
      .block-title {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 400;
        padding: 20px 0;
        margin: 0 10px;
        border-bottom: 1px solid #eeeeee;
        width: 100%;
      }
      .article:last-child{
        margin-bottom: 0;
      }
      .article {
        flex: unset;
        padding: 10px;
        margin: 10px 0;
        border-radius: 4px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        .article-header {
          margin-bottom: 10px;
          .title {
            width: 75%;
            height: auto;
            padding: 0 10px 0 0;
            font-size: 15px;
            line-height: 20px;
          }
          .publication-date {
            padding: 12px 0 0 0;
          }
        }
        .article-body {
          .article-img {
            min-width: 300px;
            height: 178px;
            background: #eeeeee;
            margin: 0 -10px 15px -10px;
          }
          .article-description {
            height: auto;
            padding: 0;
            line-height: 19px;
            margin-bottom: 15px;
          }
          .tags {
            padding: 0px;
            margin-bottom: 10px;
          }
        }
      }
      .article:nth-child(4) {
        display: none;
      }
      .one-block.first {
        padding: 10px 0;
        .deputy-data-header {
          font-family: Open Sans;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        .deputy-data-body {
          padding: 0;
          .separator {
            width: 100%;
            height: 1px;
            background-color: #eeeeee;
            margin-bottom: 20px;
          }

          #wrapper-file-mobile {
            height: auto;
            border-top: 1px solid #eeeeee;
            margin: 0;
            padding: 20px 0;
            overflow: auto;
            color: #4e4e4e;
            font-size: 14px;
            font-family: 'Open Sans', sans-serif;
            table {
              tbody {
                tr {
                  td {
                    padding: 7px 0;
                  }
                }
              }
            }
          }

          .view-on-map {
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            height: auto;
            position: unset;
            margin: 0 0 20px 0;
            padding: 0;
            color: #222222;
            text-transform: uppercase;
            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
</style>
