<template lang="pug">
  .company-product.expert-evaluations
    reporting-periods( :title="expertEvaluations.title" :error="isError" :reporting_periods="expertEvaluations.reporting_periods" v-on:dataByYear="expertEvaluationsRequest")
    template(v-if="!isError")
      .table-part
        #wrapper_xlsx(v-html="file")
      .download-file
        button.btn.download-button(v-on:click="downloadFile") Завантажити набір
    template(v-else)
      .preview-title
        | {{ isError }}
</template>

<script>
  import XLSX from 'xlsx'
  import reportingPeriods from '../company_products/ReportingPeriods.vue'

  export default {
    name: "ExpertEvaluations",
    components: {
      reportingPeriods
    },
    data() {
      return {
        file: '',
        isError: false,
        expertEvaluations: {},
        year: new Date().getFullYear()
      }
    },
    created() {
      if (this.$route.params.id) {
        this.expertEvaluationsRequest();
      }
    },
    watch: {
      $route(to, from) {
        this.expertEvaluationsRequest(this.year);
      }
    },
    computed: {
      currentFileUrl () {
        return this.expertEvaluations.file_url;
      },
    },
    methods: {
      expertEvaluationsRequest(year) {
        if (this.$route.params.id !== "index") {
          if (year) {
            this.year = year;
          } else {
            this.year = this.year - 1;
          }
          let params = { year: this.year, id: this.$route.params.id };
          this.$store.dispatch('companyProducts/getExpertEvaluations', params).then(() => {
            this.isError = false;
            this.expertEvaluations = this.$store.getters['companyProducts/getExpertEvaluations'];
            this.getFile();
          }).catch(() => {
            this.isError = this.$store.getters['companyProducts/getError'];
          });
        }
      },
      downloadFile() {
        let link = document.createElement('a');
        link.href = this.currentFileUrl;
        link.setAttribute('download', this.expertEvaluations.file_name);
        document.body.appendChild(link);
        link.click();
      },
      getFile() {
        let that = this;
        fetch(this.currentFileUrl).then(function (response) {
          return response.arrayBuffer();
        }).then(function (array_buffer) {
          let data = new Uint8Array(array_buffer);
          let wb = XLSX.read(data, {type: 'array'});
          that.file = XLSX.write(wb, {type: 'string', bookType: 'html'});
        });
      }
    }
  }
</script>
