<template lang="pug">
  .mobile-chart-wrapper
    .mobile-chart( v-for="n in deputy.criteria_values.length" :key="'chart-' + n")
      .chart-heading
        .mark-title( v-html="deputy.criteria_names[n - 1]")
        .mark-right-value
          .mark-value(
            :style="{'color': deputy.criteria_colors[n - 1]}"
            :class="{'not-used-value': deputy.criteria_colors[n - 1] === 'transparent'}"
            v-html="valueFormatter(deputy.criteria_colors[n - 1], deputy.criteria_values[n - 1])"
          )
          .chart-bar
            .chart-bar-background(
              :style="chartBarBackgroundStyles(deputy.criteria_colors[n - 1], deputy.criteria_values[n - 1])"
            )
      .divider(v-if="n != deputy.criteria_values.length")
</template>

<script>
  export default {
    name: "ChartMobile",
    props: {
      deputy: {
        type: Object,
        default: () => {}
      }
    },
    data () {
      return {
        colors: ['#e31e24', '#ec474b', '#ff9999', '#67c3e8', '#00a0e3'],
        na: {
          color: 'transparent',
          value: -1,
          title: '&#8212;'
        }
      }
    },
    watch: {
      deputy: {
        handler (deputy) {
          console.log("deputy:", deputy)
        }
      }
    },
    methods: {
      valueFormatter(color, value) {
        if (value === this.na.value || color === this.na.color) return this.na.title
        if (value >= 0) return value
        return color === this.na.color ? this.na.title : value
      },
      chartBarBackgroundStyles (color, value) {
        if (color === 'transparent') return {width: '100%', 'background-color': '#b2b2b2'}
        return {width: '34px', 'background-color': color}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .mobile-chart-wrapper {
    padding: 0 10px;
    .mobile-chart {
      margin-bottom: 9px;
    }
    .chart-heading {
      display: flex;
      justify-content: space-between;
      margin-bottom: 7px;
    }
    .mark-value {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 14px;
      margin-left: auto;
      color: white;
      font-size: 10px;
      font-weight: 400;
      margin-right: 5px;
      &.not-used-value {
        color: #222222;
      }
    }
    .mark-title {
      width: calc(100% - 44px);
      color: #222222;
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 400;
      line-height: 1.4;
      display: inline-flex;
      align-self: center;
    }
    .divider {
      border: .5px solid #EEEEEE;
      margin-bottom: 9px;
    }
    .chart-bar {
      margin-top: 6px;
      height: 6px;
      width: 34px;
    }
    .chart-bar-background {
      height: inherit;
      width: 0;
    }
  }
</style>
