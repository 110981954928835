<template lang="pug">
  .home-page-filter-modal
    header.modal-header
      .title Налаштування пошуку
      i.fas.fa-times.close-modal-btn( @click="closeModal")
    section.modal-body
      .row
        .col-md-3.mb-3.single-filter-block
          input#check-all( type='checkbox' v-model='checkAll' @change="resetFilters()")
          label( for='check-all') {{ checkAllLabel }}
        .col-md-3.mb-3.single-filter-block
          input#only-total( type='checkbox' v-model='onlyTotal' @change="onlyTotalFilters()")
          label( for='only-total') Тільки загальна оцінка
      .row
        template( v-for="(filter, index) in sortedFilters")
          .col-md-3.single-filter-block( v-if="checkBoxException(filter.alias)")
            input( type='checkbox' :id='"filter-" + index' v-model='filter.show')
            label( :for='"filter-" + index') {{ filter.name }}
    footer.modal-footer
      button.btn.cancel-filters-btn( @click="resetFilters()") Відмінити
      button.btn.use-filters-btn( @click="applyFilters()") Готово
</template>

<script>
  export default {
    name: "FilterModal",
    props: ['filters'],
    data() {
      return {
        checkAll: true,
        onlyTotal: false,
        exceptionsList: ['TOTAL', 'full_name']
      }
    },
    created() {
      this.checkAll = this.$store.getters['others/getMainPageModalFiltersAll'];
      this.onlyTotal = this.$store.getters['others/getMainPageModalFiltersOnlyTotal'];
    },
    computed: {
      checkAllLabel() {
        return this.checkAll ? 'Очистити всі' : 'Виділити всі';
      },
      sortedFilters() {
        let arr = [];
        Object.assign(arr, this.filters);

        return arr.sort((a, b) => a.name.localeCompare(b.name, 'ua', {ignorePunctuation: true}))
      }
    },
    methods: {
      onlyTotalFilters() {
        this.checkAll = !this.onlyTotal;
        this.$store.dispatch('others/getMainPageModalFiltersAll', this.checkAll);
        this.$store.dispatch('others/getMainPageModalFiltersOnlyTotal', this.onlyTotal);
        this.changeFiltersValue();
      },
      closeModal() {
        this.$emit('close');
      },
      applyFilters() {
        this.closeModal();
      },
      resetFilters() {
        this.onlyTotal = false;
        this.$store.dispatch('others/getMainPageModalFiltersAll', this.checkAll);
        this.$store.dispatch('others/getMainPageModalFiltersOnlyTotal', this.onlyTotal);
        this.changeFiltersValue();
      },
      changeFiltersValue() {
        this.filters.forEach(filter => {
          if (this.checkBoxException(filter.alias)) {
            filter.show = this.checkAll;
          }
        })
      },
      checkBoxException(alias) {
        return this.exceptionsList.indexOf(alias) === -1
      }
    }
  }
</script>

<style lang="scss">
  .home-page-filter-modal {
    .modal-header {
      .title {
        text-transform: uppercase;
        font-weight: bold;
      }

      .close-modal-btn {
        color: #9e9e9e;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.15s linear;

        &:hover {
          opacity: 1;
          transition: opacity 0.15s linear;
        }
      }
    }

    .modal-body {
      .single-filter-block {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        label {
          margin-bottom: 0;
        }

        input[type="checkbox"]:checked, input[type="checkbox"]:not(:checked) {
          position: absolute;
          left: -9999px;
        }

        input[type="checkbox"]:checked + label, input[type="checkbox"]:not(:checked) + label {
          display: inline-block;
          position: relative;
          padding-left: 20px;
          line-height: 20px;
          cursor: pointer;
        }

        p {
          background: rgb(128, 178, 128)
        }

        input#check-all + label, input#only-total + label {
          font-weight: bold;
          font-size: 13px;
        }

        input[type="checkbox"]:checked + label:before, input[type="checkbox"]:not(:checked) + label:before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -6px;
          width: 13px;
          height: 13px;
          border: 1px solid #2fb199;
          background-color: #ffffff;
          border-radius: 2px;
        }

        input[type="checkbox"]:checked + label:after, input[type="checkbox"]:not(:checked) + label:after {
          content: "";
          position: absolute;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          transition: all 0.2s ease;
          left: 2px;
          top: 50%;
          margin-top: -3px;
          width: 9px;
          height: 5px;
          border-radius: 1px;
          border-left: 3px solid #2fb199;
          border-bottom: 3px solid #2fb199;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }

        input[type="checkbox"]:not(:checked) + label:after {
          opacity: 0;
        }

        input[type="checkbox"]:checked + label:after {
          opacity: 1;
        }
      }
    }

    .modal-footer {
      .cancel-filters-btn, .use-filters-btn {
        font-weight: bold;
        font-size: 14px;
        width: 180px;
        height: 35px;
        border-radius: 4px;
        text-transform: uppercase;

        &:focus {
          box-shadow: none;
        }
      }

      .cancel-filters-btn {
        color: black;
        border: 2px black solid;
        background-color: white;
      }

      .use-filters-btn {
        color: white;
        background-color: #05a081;
      }
    }
  }
</style>
