<template lang="pug">
  .container-fluid#about-deputies-container
    .background( :style="{'background-image': 'url(' + backgroundImage + ')'}")
      .container
        .row
          .col-md-12
            breadcrumbs
          .col-md-12.overflow-auto
            .submenu
              nav.navbar.navbar-expand-lg.navbar-light.w-100.pl-0
                ul.navbar-nav.mt-2.mt-lg-0
                  template( v-for="link in subMenuLinks")
                    li.nav-item
                      router-link.nav-link.menu-link( :to="subMenuLink(link)" v-if="link.node_type !== 'link'")
                        | {{ link.title }}
                      a.nav-link.menu-link( :href="'//' + link.outer_link" target="_blank" v-else)
                        | {{ link.title }}
    .container
      .row
        template( v-if="$route.params.id")
          .col-md-4
            .councils-list
              .council-list-mobile
                .btn.counsil-header(@click="toogleTable")
                  span(v-if="$route.query.title")
                    |{{$route.query.title}}
                  span(v-else)
                    | ОБРАТИ МІСЬКУ РАДУ 
                  i.fas.fa-chevron-down(:class="{'fa-rotate-180':open}")
                div(v-show-slide='open')
                  template( v-for="council in councils")
                    .slide-counsil(@click="ChooseCounsil()")
                      router-link.council-title.d-block( :to="{ name: $route.name, params: { id: council.id} ,query:{title:council.title }, hash: isMobile() ? '#preview-file-container' : ''}")
                        | {{ council.title }}
              .council-list-desktop
                 template( v-for="council in councils")
                  router-link.council-title.d-block( :to="{ name: $route.name, params: { id: council.id }, hash: isMobile() ? '#preview-file-container' : ''}" )
                    | {{ council.title }}
          .col-md-8
            router-view
        template( v-else)
          .col-md-12
            router-view
</template>

<script>
import breadcrumbs from '../layout/Breadcrumbs'
import { isMobile } from '../utils/mobileDetect'
export default {
  name: 'AboutDeputies',
  components: {
    breadcrumbs
  },
  data() {
    return {
      councils: [],
      componentAlias: 'deputies_more',
      open: false
    }
  },
  created() {
    this.getCouncils()
  },
  computed: {
    backgroundImage() {
      return require('@/body_top_background.png')
    },
    subMenuLinks() {
      let links = []
      this.$store.getters['menus/getMenus'].forEach(item => {
        if (item.alias === this.componentAlias) {
          links = item.sub_menus
        }
      })
      return this.isMobile() ? this.preparedLinks([...links], this.toSnakeCase(this.$route.name)) : links
    }
  },
  methods: {
    isMobile,
    getCouncils() {
      this.$store.dispatch('councils/getCouncilsInfo').then(() => {
        this.councils = this.$store.getters['councils/getCouncilsInfo']
      })
    },
    subMenuLink(link) {
      return link.alias === this.componentAlias
        ? '/' + this.componentAlias + '/' + this.$route.params.id
        : '/' +
            this.componentAlias +
            '/' +
            (link.node_type === 'static'
              ? link.alias + '/' + this.$route.params.id
              : 'advanced_info/' + link.alias)
    },
    toogleTable() {
      this.open = !this.open
    },
    ChooseCounsil() {
      this.open = !this.open
    },
    preparedLinks(links, alias){
      if (links.length == 0) return links
      let res = [];

      for(let link of links) {
        if (link.alias == alias) {
          if (links.indexOf(link) == 0) break
          links.splice(links.indexOf(link), 1)
          res = [link, ...links]
          break
        }
      }
      this.setNavScrollToStart()
      return res.length > 0 ? res : links
    },
    toSnakeCase(str) {
      return str.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');
    },
    setNavScrollToStart() {
      if (document.getElementsByClassName('overflow-auto').length > 0) {
        document.getElementsByClassName('overflow-auto').item(0).scrollLeft = 0
      }
    }
  }
}
</script>

<style lang="scss">
@media (max-width: 991px) {
  div#about-deputies-container {
    .background {
      .submenu {
        .navbar {
          .navbar-nav {
            -ms-flex-direction: unset;
            flex-direction: unset;

            .nav-item {
              .nav-link.menu-link {
                margin-right: 20px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  div#about-deputies-container {
    padding: 10px 10px 40px;

    .background {
      height: auto;

      .submenu {
        .navbar {
          padding: 10px 10px 23px 10px;

          .navbar-nav {
            .nav-item {
              .nav-link.menu-link {
                padding: 0;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
    .container {
      .row {
        padding: 0;

        .col-md-4 {
          padding: 0;

          .councils-list {
            padding: 10px 15px 0;
            margin-top: 10px;
            height: auto;

            .council-list-mobile {
              display: block;
            }
            .council-list-desktop {
              display: none;
            }

            .btn.counsil-header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 0 10px 0;
            }

            .council-title {
              font-size: 14px;
              font-weight: bold;
            }
          }
        }

        .col-md-8 {
          padding: 0;
        }
      }
    }
  }
}

#about-deputies-container {
  background-color: #f8f8f8;
  padding-bottom: 20px;
  .background {
    height: 215px;
    margin: 0 -15px;

    .breadcrumbs {
      padding: 0 16px;
      margin: 30px 0 20px;
      color: #ffffff;
      font-size: 12px;
    }

    .submenu {
      font-size: 16px;
      line-height: 20px;

      .navbar {
        .navbar-nav {
          .nav-item {
            .nav-link.menu-link {
              color: #ffffff;
              text-transform: uppercase;
              padding-bottom: 6px;
              padding-left: 0;
              opacity: 0.8;
              margin-right: 72px;
              cursor: pointer;

              &.router-link-active {
                opacity: 1;
                cursor: default;
                border-bottom: 2px solid #fff;
                padding-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  .councils-list {
    margin-top: -65px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    background-color: #ffffff;
    padding: 14px 20px 16px 20px;
    height: 86vh;
    overflow: auto;

    .slide-counsil {
      height: 46px;
      display: flex;
      align-items: center;
      border-top: 1px solid #eeeeee;
    }

    &::-webkit-scrollbar-track {
      background-color: #b8b8b8;
    }

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 271px;
      background-color: #626262;
    }

    .council-list-mobile {
      display: none;
    }

    .council-list-desktop {
      display: block;
    }

    .council-title {
      font-size: 14px;
      line-height: 36px;
      color: #555555;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    .router-link-active {
      color: #05a081;
    }

    .router-link-exact-active {
      font-weight: bold;
    }
  }
}

@import '../assets/stylesheets/company_products.scss';
</style>
