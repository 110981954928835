<template lang="pug">
  th(scope='col')
    .column-header( @click="changeSort" :title="object[0]")
      .column-title
        span {{ object[0] }}
      .caret-container
        i.fas.fa-caret-up.d-block( :class="{disabled: asc}")
        i.fas.fa-caret-down.d-block( :class="{disabled: desc}")
</template>

<script>
  export default {
    name: "PreviewHeader",
    props: ['object'],
    data() {
      return {
        counter: 0
      }
    },
    computed: {
      asc() {
        return this.counter === 1
      },
      desc() {
        return this.counter === 2
      },
      sortMethod() {
        switch (this.counter) {
          case 1:
            return {sortType: 'desc', id: this.object[1]};
          case 2:
            return {sortType: 'asc', id: this.object[1]};
          default:
            return {sortType: '', id: this.object[1]}
        }
      }
    },
    methods: {
      resetCounter() {
        this.counter = 0;
      },
      changeSort() {
        this.counterUpdate();
        this.$emit('change-sort', this.sortMethod);
      },
      counterUpdate() {
        this.counter++;
        this.counter = this.counter > 2 || this.counter < 0 ? 0 : this.counter;
      }
    }
  }
</script>

<style scoped lang="scss">
  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    padding: 0;

    .column-header {
      /*box-shadow: 0px 0px 0px 1px #dee2e6 inset;*/
      border: 1px #dee2e6 solid;
      margin: -1px;
      padding: 0 5px;
      height: 50px;
      overflow: hidden;
      display: flex;
      justify-content: left;
      align-items: center;
      cursor: pointer;


      .column-title {
        height: 50px;
        padding: 5px;
        
        &:after {
          content: '';
          position: absolute;
          top: 0px;
          right: 18px;
          width: 50px;
          height: 48px;
          background: rgba(237, 237, 237, 0);
          background: -moz-linear-gradient(left, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
          background: -webkit-gradient(left top, right top, color-stop(0%, rgba(237, 237, 237, 0)), color-stop(5%, rgba(246, 246, 246, 0.06)), color-stop(16%, rgba(255, 255, 255, 0.18)), color-stop(87%, rgba(255, 255, 255, 1)));
          background: -webkit-linear-gradient(left, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
          background: -o-linear-gradient(left, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
          background: -ms-linear-gradient(left, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
          background: linear-gradient(to right, rgba(237, 237, 237, 0) 0%, rgba(246, 246, 246, 0.06) 5%, rgba(255, 255, 255, 0.18) 16%, rgba(255, 255, 255, 1) 87%);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#ffffff', GradientType=1);
        }
      }

      .caret-container {
        margin-left: auto;
      }
    }
  }
  @media screen and(max-width:425px) {
    th .column-header .column-title{
        height: auto;
        padding: 0;

        span{
          white-space: nowrap;
        }

        &:after {
          height: auto;
          position: absolute;
          top: 0px;
          right: 0;
          width: 0;
        }
    }
  }
</style>