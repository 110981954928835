<template lang="pug">
  .container-fluid#footer
    .container
      .row.d-flex.align-items-center(v-if="isMobile()")
        .row.footer-images-isMobile
          .col-12.col-sm-4.col-md-3.footer-image 
            span 
              img(:src="nedMobile")
            span 
              img(:src="KRYlogo")
        .row
          .project-info
            | Платформа «Прозорі місцеві бюджети» створена в рамках програми «Електронне врядування задля підзвітності влади
            |  та участі громади» (EGAP), що фінансується Швейцарською Конфедерацією та виконується Фондом Східна Європа. 
            |  Платформа розроблена для реалізації Методології оцінки прозорості місцевих бюджетів, що створена Фондом Східна 
            |  Європа спільно з Громадським партнерством «За прозорі місцеві бюджети!» в партнерстві з Асоціацією міст України 
            |  в рамках проекту «Публічні бюджети від А до Я: Інформування, активізація та залучення громадянського суспільства» 
            |  за підтримки Європейського Союзу.
          .project-support
            | Технічна підтримка 
            strong support@tlb.in.ua
      .row.d-flex.align-items-center(v-if="!isMobile()")
        .row.footer-images
          .col-12.col-sm-4.col-md-3.footer-image
            img(:src="nedDesktop")
          .col-12.col-sm-4.col-md-3.footer-image
            img(:src="vidrodzhennia")
          .col-12.col-sm-4.col-md-3.footer-image
            img(:src="odessa")
        .row
          .project-info
            | Громадська кампанія «Атестація депутатів місцевих рад» спрямована на сприяння вирішенню проблеми низького
            |  рівня відкритості, прозорості та підзвітності виборцям депутатів місцевих рад України, а також підвищення
            |  поінформованості громадян про діяльність місцевих депутатів. Проект «Атестація депутатів місцевих рад»
            |  реалізується Одеською обласною організацією ВГО «Комітет виборців України» разом з партнерськими
            |  організаціями з 16-ти регіонів України за підтримки Національного фонду демократії (NED, США). У 2016
            |  році апробація методології відбулася в місцевих радах Одеської області за підтримки Міжнародного фонду
            |  «Відродження», в 2018-му році за підтримки Міжнародного фонду «Відродження» запущено розробку
            |  веб-платформи «Місцеві депутати. Атестація».


    .row.copyright
      i.far.fa-copyright
      | Всі права захищені. 2019
</template>

<script>
  import {isMobile} from '../utils/mobileDetect'
  export default {
    name: "Footer",
    computed: {
      nedMobile () {
        return require('@/logo.png')
      },
      nedDesktop () {
        return require('@/NEDlogo.png')
      },
      KRYlogo () {
        return require('@/KRYlogo.png')
      },
      odessa () {
        return require('@/logo_CVU_Odessa.png')
      },
      vidrodzhennia () {
        return require('@/logo_vidrodzhennia.gif')
      }
    },
    methods: {
      isMobile
    }
  }
</script>

<style lang="scss">
  #footer {
    font-size: 12px;
    line-height: 15px;
    margin-top: 20px;
    .footer-images {
      text-align: center;
      display: inline-block;

      .footer-image {
        margin-bottom: 20px;
        display: inline-block;
       
        img {
          width: 85%;
          height: auto;
        }
      }
    }

    .footer-images-isMobile {
        text-align: center;
        display: inline-block;
        .footer-image {
          margin-bottom: 20px;
          display: flex;
          span:first-child {
            margin-right: 20px;
          }
          img {
            width: 80px;
            height: 80px;
          }
        }
      }

    .project-info {
      color: #555555;
      padding: 0 10px;
    }
    .project-support{
      color: #555555;
      margin-top: 20px;
      margin-left: 15px;
    }

    .copyright {
      line-height: 48px;
      margin-top: 25px;
      background-color: #1a221e;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;

      .fa-copyright {
        margin-right: 5px;
      }
    }
  }
  @media (max-width: 767px) {
    #footer.container-fluid {
       padding: 0 15px !important;
      .project-info {
      padding: 0 15px;
      }
      .copyright {
        line-height: 36px;
        margin-top: 20px;
      }
    }
  }
</style>
 